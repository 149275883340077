/* card */

.card {
  // --sk-card-spacer-y: 1rem;
  // --sk-card-spacer-x: 1rem;
  // --sk-card-title-spacer-y: .5rem;
  // --sk-card-title-color: red;
  // --sk-card-subtitle-color: ;
  // --sk-card-border-width: var(--sk-border-width);
  // --sk-card-border-color: var(--sk-border-color-translucent);
  --sk-card-border-radius: 1rem;
  // --sk-card-box-shadow: ;
  --sk-card-inner-border-radius: 1rem;
  // --sk-card-cap-padding-y: .5rem;
  // --sk-card-cap-padding-x: 1rem;
  // --sk-card-cap-bg: rgba(var(--sk-body-color-rgb), .03);
  // --sk-card-cap-color: ;
  // --sk-card-height: ;
  --sk-card-color: var(--sk-gray-700);
  // --sk-card-bg: var(--sk-body-bg);
  // --sk-card-img-overlay-padding: 1rem;
  // --sk-card-group-margin: .75rem;

  
  .card-img-top {
    height: 200px;
    object-fit: cover;
    padding: 1px;
  }
  
  .card-title {
    a {
      color: currentColor;
      display: block;
      &:hover {
        color: var(--sk-link-color);
      }
    }
  }

  .list-group {
    --sk-list-group-item-padding-x: 0;
  }

  /* .card-text {
    font-size: $font-size-sm;
    //color: var(--sk-gray-700);
  } */
}