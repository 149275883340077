// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

$prefix: 'sk-';

$primary: #162f5a;//#173961;
$dark: #10264c;//#0e2043;//#19243a;

$dark-text: $dark;
$body-color: $dark;

//$link-color: red;
$font-size-xs: 0.75rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

$theme-colors: (
  "primary": $primary,
  "orange": $orange,
  "light": $gray-100,
  "dark": $dark
);

$spacers: map-merge($spacers, (
  6: $spacer * 4,
  7: $spacer * 6
));

$font-sizes: map-merge($font-sizes, (
  "xs": $font-size-xs,
  "sm": $font-size-sm,
  "lg": $font-size-lg,
  "xl": $font-size-xl
));

// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

@import "~bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`

$utilities: map-merge(
  $utilities, (
    "border-start": map-merge(
      map-get($utilities, "border-start"),
      ( responsive: true ),
    ),
    "font-size": map-merge(
      map-get($utilities, "font-size"),
      ( responsive: true ),
    ),
  )
);

@import "~bootstrap/scss/utilities/api";

// 8. Custom styles

/* base */

@import "./base/root";
@import "./base/focus";
@import "./base/bi";

/* components */

@import "./components/navbar";
//
@import "./components/post";
@import "./components/attr-list";
@import "./components/banner";
@import "./components/gallery";
@import "./components/widget";
//
@import "./components/breadcrumb";
@import "./components/card";
@import "./components/accordion";
@import "./components/nav";
@import "./components/pagination";
@import "./components/popover";
//
@import "./components/section-intro";
@import "./components/section-trail";
@import "./components/section-attrs";
@import "./components/section-passport";
@import "./components/section-coin";
//
@import "./components/tourist-points";
//
@import "./components/footer";

/* utilities */

// @import "./utilities/name....";