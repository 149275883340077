/* Post */

#post {
  --sk-link-color: red !important;
}


#post .attr-list {
  --attr-li-flex: 0 1 auto;
  --attr-text-color: transparent;
  a {
    font: 0/0 a;
    display: block;
  }
}