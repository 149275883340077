/* trail-map */

.map-svg {
  [data-view=one] & {
    max-height: 700px;
  }
  [data-view=two] & {
    max-height: 564px;
    aspect-ratio: 1;
    margin-inline: auto;
    display: block;
  }
}


.trail-map-slide {
  [data-view=two] & {
    transform-origin: center;

    /* Set Slide Position */

    &.slide-w-wm { transform: scale(2.5)translate(-4.2%, -45%) }
    &.slide-w-kp { transform: scale(2.5)translate(12%, -62%) }
    &.slide-w-pm { transform: scale(2.5)translate(9.5%, -31%) }

    /* Smooth Position Change */

    &.slide-smooth {
      transition: transform 750ms cubic-bezier(0, 0, .75, 1);
    }
    
    /* Track Drawing Animation Setting */

    $dasharray_w-wm: 653.994;
    $dasharray_w-kp: 462.208;
    $dasharray_w-pm: 215.693;
    //
    &.slide-w-wm #w-wm .map-svg-track {
      stroke-dasharray: $dasharray_w-wm;
    }
    &.slide-w-wm.slide-in-right #w-wm .map-svg-track {
      stroke-dashoffset: $dasharray_w-wm;
    }
    &.slide-w-wm.slide-in-left #w-wm .map-svg-track {
      stroke-dashoffset: -$dasharray_w-wm;
    }
    //
    &.slide-w-kp #w-kp .map-svg-track {
      stroke-dasharray: $dasharray_w-kp;
    }
    &.slide-w-kp.slide-in-right #w-kp .map-svg-track {
      stroke-dashoffset: -$dasharray_w-kp
    }
    &.slide-w-kp.slide-in-left #w-kp .map-svg-track {
      stroke-dashoffset: $dasharray_w-kp
    }
    //
    &.slide-w-pm #w-pm .map-svg-track {
      stroke-dasharray: $dasharray_w-pm;
    }
    &.slide-w-pm.slide-in-right #w-pm .map-svg-track {
      stroke-dashoffset: -$dasharray_w-pm;
    }
    &.slide-w-pm.slide-in-left #w-pm .map-svg-track {
      stroke-dashoffset: $dasharray_w-pm;
    }

    /* Track and Point Animations */

    &.slide-w-wm.slide-in-left #w-wm,
    &.slide-w-wm.slide-in-right #w-wm,
    &.slide-w-kp.slide-in-left #w-kp,
    &.slide-w-kp.slide-in-right #w-kp,
    &.slide-w-pm.slide-in-left #w-pm,
    &.slide-w-pm.slide-in-right #w-pm {
      .map-svg-track {
        animation: 750ms ease both drawTrack;
      }
      .map-svg-point {
        animation: 500ms ease both 500ms fadeIn;
      }
      .map-svg-point-name {
        animation: 500ms ease both 500ms fadeIn;
      }
    }

    /* Hide Elements Of Unselected Group */

    &.slide-w-wm > :not(#w-wm),
    &.slide-w-kp > :not(#w-kp),
    &.slide-w-pm > :not(#w-pm) {
      > :not(.map-svg-region) {
        visibility: hidden;
        transition: none;
      }
    }

  } // end: view-two
}


.map-svg-region {
  stroke: #66778a;
  stroke-width: 1px;
  cursor: pointer;
  //
  [data-view=two] & {
    fill: transparent;
  }
  [data-view=two] .slide-w-wm #w-kp & { cursor: url(/src/img/cursor-d-l.svg), auto }
  [data-view=two] .slide-w-wm #w-pm & { cursor: url(/src/img/cursor-u-l.svg), auto }
  [data-view=two] .slide-w-kp #w-pm & { cursor: url(/src/img/cursor-u.svg), auto }
  [data-view=two] .slide-w-kp #w-wm & { cursor: url(/src/img/cursor-u-r.svg), auto }
  [data-view=two] .slide-w-pm #w-wm & { cursor: url(/src/img/cursor-d-r.svg), auto }
  [data-view=two] .slide-w-pm #w-kp & { cursor: url(/src/img/cursor-d.svg), auto }
  [data-view=two] .slide-w-wm #w-wm &,
  [data-view=two] .slide-w-kp #w-kp &,
  [data-view=two] .slide-w-pm #w-pm & {
    pointer-events: none;
  }
}


.map-svg-region-name {
  fill: white;
  font-size: 1rem;
  pointer-events: none;
  text-transform: uppercase;
  transition: fill 200ms;
  //
  .map-svg-group.hover & {
    fill: var(--sk-orange);
  }
  //
  [data-view=two] & {
    display: none;
  }
}


.map-svg-track {
  fill: none;
  stroke: rgba(255 255 255 / 50%);
  stroke-width: 2;
  pointer-events: none;
  transition: stroke 200ms;
  //
  .map-svg-group.hover &,
  [data-view=two] & { // może dodać .current?
    stroke: var(--sk-orange);
  }
  //
  @keyframes drawTrack { 
    to { stroke-dashoffset: 0 }
  }
}


.map-svg-point {
  r: 4px;
  stroke: white;
  stroke-width: 1.5px;
  fill: var(--sk-orange);
  transition: all 250ms;
  cursor: pointer;
  // on hover state
  //&.hover {}
  // on current state
  &.current {
    r: 5px;
    fill: var(--sk-blue);
    pointer-events: none;
    transition-duration: 500ms;
  }
  // hide inactive point
  &:not(.active) {
    display: none;
  }
  // nieużywane
  /* @keyframes pulseMapPiont {
    from { r:  5; }
    50%  { r: 8; }
    to   { r: 5;  }
  } */
}


.map-svg-point-name {
  fill: rgba(255 255 255 / 75%);
  font-size: .625rem;
  transition: fill 250ms;
  cursor: pointer;
  // on hover state
  .map-svg-point.hover + & {
    fill: var(--sk-orange);
  }
  // on current state
  .map-svg-point.current + & {
    fill: white;
    pointer-events: none;
  }
  // hide inactive name
  .map-svg-point:not(.active) + & {
    display: none;
  }
}

@keyframes fadeIn {
  from { 
    opacity: 0;
    visibility: hidden;
  }
  to { 
    opacity: 1;
    visibility: visible;
   }
}