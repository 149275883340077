/* Breadcrumb */

.breadcrumb {
  // --sk-breadcrumb-padding-x: 0;
  // --sk-breadcrumb-padding-y: 0;
  // --sk-breadcrumb-margin-bottom: 1rem;
  // --sk-breadcrumb-bg: ;
  // --sk-breadcrumb-border-radius: ;
  // --sk-breadcrumb-divider-color: var(--sk-secondary-color);
  // --sk-breadcrumb-item-padding-x: .5rem;
  // --sk-breadcrumb-item-active-color: var(--sk-secondary-color);
}