/* widget */

.widget {
  --sk-heading-color: var(--sk-body-color);
  color: var(--sk-gray-700);
  font-size: $font-size-sm;

  +.widget {
    border-top: 1px solid var(--sk-border-color);
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }

  p {
    margin-bottom: .625rem;
  }
}