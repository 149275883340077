/* Focus Management */

:focus,
:focus-visible {
	outline: 2px dotted var(--sk-orange) !important;
	outline-offset: 4px !important;
	z-index: 5 !important;
}
:focus:not(:focus-visible) {
	outline: none !important;
}