/* Trail */

#trail {
  background-color: var(--sk-dark);
  overflow: hidden;
}


.trail-title {
  position: relative;
  span {
    color: var(--sk-orange);
    margin-top: .375rem;
    font-size: $font-size-lg;
    display: block;
  }
}


.trail-view {
  transform-origin: center;
	//
  &[data-view=two] {
    background-image: url(../img/trail-bg.png);
    background-repeat: no-repeat;
    background-position: 85% 100%; // x y
    background-size: 600px;
    @include media-breakpoint-up(md) {
      background-size: 650px;
    }
    @include media-breakpoint-up(lg) {
      background-size: 750px;
    }
  }
  //
  &.zoomOutView {
    animation: 250ms ease-out both zoomOutView;
  }
  &.zoomInView {
    animation: 250ms ease-in both zoomInView;
  }
  &.zoomOutView2 {
    animation: 250ms ease-in both reverse zoomInView;
  }
  &.zoomInView1 {
    animation: 250ms ease-in both reverse zoomOutView;
  }
  //
  @keyframes zoomOutView {
    from { transform: none; opacity: 1; }
    to { transform: scale(95%); opacity: 0; }
  }
  
  @keyframes zoomInView {
    from { transform: scale(105%); opacity: 0; }
    to { transform: none; opacity: 1; }
  }
}


.trail-row {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @media (min-width: 992px) {
		flex-direction: row;
    align-items: center;
    gap: 30px;
	}
}


.trail-col-post {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // min-height: 430px;

	@media (min-width: 992px) {
    flex: 1 1 50%;
	}

  [data-view=one] & {
    display: none;
  }
}


.trail-col-map {
  flex: 1 1 100%;
  width: 100%;
  //
  [data-view=two] & {
    flex-basis: 50%;
    @media (max-width: 991.98px) {
      max-width: 450px;
      margin: 0 auto;
    }
  }
}