/* Pagination */

.pagination {
  // --sk-pagination-padding-x: .625rem;
  // --sk-pagination-padding-y: .375rem;
  --sk-pagination-font-size: #{$font-size-sm};
  // --sk-pagination-color: var(--sk-gray-700);
  // --sk-pagination-bg: var(--sk-body-bg);
  --sk-pagination-border-width: 0;
  // --sk-pagination-border-color: var(--sk-border-color);
  --sk-pagination-border-radius: .25rem;
  --sk-pagination-hover-color: var(--sk-link-color);
  --sk-pagination-hover-bg: var(--sk-gray-100);
  // --sk-pagination-hover-border-color: var(--sk-border-color);
  // --sk-pagination-focus-color: var(--sk-link-hover-color);
  // --sk-pagination-focus-bg: var(--sk-secondary-bg);
  // --sk-pagination-focus-box-shadow: 0 0 0 .25rem #17396140;
  --sk-pagination-active-color: var(--sk-dark);
  --sk-pagination-active-bg: var(--sk-primary-bg-subtle);//var(--sk-gray-200);
  // --sk-pagination-active-border-color: #173961;
  --sk-pagination-disabled-color: var(--sk-gray-500);
  --sk-pagination-disabled-bg: transparent;
  // --sk-pagination-disabled-border-color: var(--sk-border-color);
  
  font-weight: 500;
  gap: .25rem;

  >li:last-of-type,
  >li:first-of-type {
    --sk-pagination-padding-x: .5rem;
  }

  .page-link {
    border-radius: var(--sk-pagination-border-radius);
  }
}