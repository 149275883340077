:root, [data-bs-theme="light"] {
  --sk-blue: #0d6efd;
  --sk-indigo: #6610f2;
  --sk-purple: #6f42c1;
  --sk-pink: #d63384;
  --sk-red: #dc3545;
  --sk-orange: #fd7e14;
  --sk-yellow: #ffc107;
  --sk-green: #198754;
  --sk-teal: #20c997;
  --sk-cyan: #0dcaf0;
  --sk-black: #000;
  --sk-white: #fff;
  --sk-gray: #6c757d;
  --sk-gray-dark: #343a40;
  --sk-gray-100: #f8f9fa;
  --sk-gray-200: #e9ecef;
  --sk-gray-300: #dee2e6;
  --sk-gray-400: #ced4da;
  --sk-gray-500: #adb5bd;
  --sk-gray-600: #6c757d;
  --sk-gray-700: #495057;
  --sk-gray-800: #343a40;
  --sk-gray-900: #212529;
  --sk-primary: #162f5a;
  --sk-orange: #fd7e14;
  --sk-light: #f8f9fa;
  --sk-dark: #10264c;
  --sk-primary-rgb: 22, 47, 90;
  --sk-orange-rgb: 253, 126, 20;
  --sk-light-rgb: 248, 249, 250;
  --sk-dark-rgb: 16, 38, 76;
  --sk-primary-text: #0a58ca;
  --sk-secondary-text: #6c757d;
  --sk-success-text: #146c43;
  --sk-info-text: #087990;
  --sk-warning-text: #997404;
  --sk-danger-text: #b02a37;
  --sk-light-text: #6c757d;
  --sk-dark-text: #10264c;
  --sk-primary-bg-subtle: #cfe2ff;
  --sk-secondary-bg-subtle: #f8f9fa;
  --sk-success-bg-subtle: #d1e7dd;
  --sk-info-bg-subtle: #cff4fc;
  --sk-warning-bg-subtle: #fff3cd;
  --sk-danger-bg-subtle: #f8d7da;
  --sk-light-bg-subtle: #fcfcfd;
  --sk-dark-bg-subtle: #ced4da;
  --sk-primary-border-subtle: #9ec5fe;
  --sk-secondary-border-subtle: #e9ecef;
  --sk-success-border-subtle: #a3cfbb;
  --sk-info-border-subtle: #9eeaf9;
  --sk-warning-border-subtle: #ffe69c;
  --sk-danger-border-subtle: #f1aeb5;
  --sk-light-border-subtle: #e9ecef;
  --sk-dark-border-subtle: #adb5bd;
  --sk-white-rgb: 255, 255, 255;
  --sk-black-rgb: 0, 0, 0;
  --sk-body-color-rgb: 16, 38, 76;
  --sk-body-bg-rgb: 255, 255, 255;
  --sk-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --sk-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --sk-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --sk-body-font-family: var(--sk-font-sans-serif);
  --sk-body-font-size: 1rem;
  --sk-body-font-weight: 400;
  --sk-body-line-height: 1.5;
  --sk-body-color: #10264c;
  --sk-emphasis-color: #000;
  --sk-emphasis-color-rgb: 0, 0, 0;
  --sk-secondary-color: #10264cbf;
  --sk-secondary-color-rgb: 16, 38, 76;
  --sk-secondary-bg: #e9ecef;
  --sk-secondary-bg-rgb: 233, 236, 239;
  --sk-tertiary-color: #10264c80;
  --sk-tertiary-color-rgb: 16, 38, 76;
  --sk-tertiary-bg: #f8f9fa;
  --sk-tertiary-bg-rgb: 248, 249, 250;
  --sk-body-bg: #fff;
  --sk-body-bg-rgb: 255, 255, 255;
  --sk-link-color: #162f5a;
  --sk-link-color-rgb: 22, 47, 90;
  --sk-link-decoration: underline;
  --sk-link-hover-color: #122648;
  --sk-link-hover-color-rgb: 18, 38, 72;
  --sk-code-color: #d63384;
  --sk-highlight-bg: #fff3cd;
  --sk-border-width: 1px;
  --sk-border-style: solid;
  --sk-border-color: #dee2e6;
  --sk-border-color-translucent: #0000002d;
  --sk-border-radius: .375rem;
  --sk-border-radius-sm: .25rem;
  --sk-border-radius-lg: .5rem;
  --sk-border-radius-xl: 1rem;
  --sk-border-radius-2xl: 2rem;
  --sk-border-radius-pill: 50rem;
  --sk-box-shadow: 0 .5rem 1rem rgba(var(--sk-body-color-rgb), .15);
  --sk-box-shadow-sm: 0 .125rem .25rem rgba(var(--sk-body-color-rgb), .075);
  --sk-box-shadow-lg: 0 1rem 3rem rgba(var(--sk-body-color-rgb), .175);
  --sk-box-shadow-inset: inset 0 1px 2px rgba(var(--sk-body-color-rgb), .075);
  --sk-emphasis-color: #000;
  --sk-form-control-bg: var(--sk-body-bg);
  --sk-form-control-disabled-bg: var(--sk-secondary-bg);
  --sk-highlight-bg: #fff3cd;
  --sk-breakpoint-xs: 0;
  --sk-breakpoint-sm: 576px;
  --sk-breakpoint-md: 768px;
  --sk-breakpoint-lg: 992px;
  --sk-breakpoint-xl: 1200px;
  --sk-breakpoint-xxl: 1400px;
}

[data-bs-theme="dark"] {
  --sk-body-color: #adb5bd;
  --sk-body-color-rgb: 173, 181, 189;
  --sk-body-bg: #212529;
  --sk-body-bg-rgb: 33, 37, 41;
  --sk-emphasis-color: #f8f9fa;
  --sk-emphasis-color-rgb: 248, 249, 250;
  --sk-secondary-color: #adb5bdbf;
  --sk-secondary-color-rgb: 173, 181, 189;
  --sk-secondary-bg: #343a40;
  --sk-secondary-bg-rgb: 52, 58, 64;
  --sk-tertiary-color: #adb5bd80;
  --sk-tertiary-color-rgb: 173, 181, 189;
  --sk-tertiary-bg: #2b3035;
  --sk-tertiary-bg-rgb: 43, 48, 53;
  --sk-emphasis-color: #fff;
  --sk-primary-text: #6ea8fe;
  --sk-secondary-text: #dee2e6;
  --sk-success-text: #75b798;
  --sk-info-text: #6edff6;
  --sk-warning-text: #ffda6a;
  --sk-danger-text: #ea868f;
  --sk-light-text: #f8f9fa;
  --sk-dark-text: #dee2e6;
  --sk-primary-bg-subtle: #031633;
  --sk-secondary-bg-subtle: #212529;
  --sk-success-bg-subtle: #051b11;
  --sk-info-bg-subtle: #032830;
  --sk-warning-bg-subtle: #332701;
  --sk-danger-bg-subtle: #2c0b0e;
  --sk-light-bg-subtle: #343a40;
  --sk-dark-bg-subtle: #1a1d20;
  --sk-primary-border-subtle: #084298;
  --sk-secondary-border-subtle: #495057;
  --sk-success-border-subtle: #0f5132;
  --sk-info-border-subtle: #055160;
  --sk-warning-border-subtle: #664d03;
  --sk-danger-border-subtle: #842029;
  --sk-light-border-subtle: #495057;
  --sk-dark-border-subtle: #343a40;
  --sk-heading-color: #fff;
  --sk-link-color: #6ea8fe;
  --sk-link-hover-color: #9ec5fe;
  --sk-link-color-rgb: 110, 168, 254;
  --sk-link-hover-color-rgb: 158, 197, 254;
  --sk-code-color: #e685b5;
  --sk-border-color: #495057;
  --sk-border-color-translucent: #ffffff26;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--sk-body-font-family);
  font-size: var(--sk-body-font-size);
  font-weight: var(--sk-body-font-weight);
  line-height: var(--sk-body-line-height);
  color: var(--sk-body-color);
  text-align: var(--sk-body-text-align);
  background-color: var(--sk-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--sk-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--sk-heading-color, inherit);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--sk-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--sk-link-color-rgb), var(--sk-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --sk-link-color-rgb: var(--sk-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--sk-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--sk-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--sk-body-bg);
  background-color: var(--sk-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--sk-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--sk-body-bg);
  border: var(--sk-border-width) solid var(--sk-border-color);
  border-radius: var(--sk-border-radius);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--sk-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --sk-gutter-x: 1.5rem;
  --sk-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--sk-gutter-x) * .5);
  padding-left: calc(var(--sk-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.row {
  --sk-gutter-x: 1.5rem;
  --sk-gutter-y: 0;
  margin-top: calc(-1 * var(--sk-gutter-y));
  margin-right: calc(-.5 * var(--sk-gutter-x));
  margin-left: calc(-.5 * var(--sk-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--sk-gutter-x) * .5);
  padding-left: calc(var(--sk-gutter-x) * .5);
  margin-top: var(--sk-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --sk-gutter-x: 0;
}

.g-0, .gy-0 {
  --sk-gutter-y: 0;
}

.g-1, .gx-1 {
  --sk-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --sk-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --sk-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --sk-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --sk-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --sk-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --sk-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --sk-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --sk-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --sk-gutter-y: 3rem;
}

.g-6, .gx-6 {
  --sk-gutter-x: 4rem;
}

.g-6, .gy-6 {
  --sk-gutter-y: 4rem;
}

.g-7, .gx-7 {
  --sk-gutter-x: 6rem;
}

.g-7, .gy-7 {
  --sk-gutter-y: 6rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --sk-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --sk-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --sk-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --sk-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --sk-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --sk-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --sk-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --sk-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --sk-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --sk-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --sk-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --sk-gutter-y: 3rem;
  }

  .g-sm-6, .gx-sm-6 {
    --sk-gutter-x: 4rem;
  }

  .g-sm-6, .gy-sm-6 {
    --sk-gutter-y: 4rem;
  }

  .g-sm-7, .gx-sm-7 {
    --sk-gutter-x: 6rem;
  }

  .g-sm-7, .gy-sm-7 {
    --sk-gutter-y: 6rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --sk-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --sk-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --sk-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --sk-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --sk-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --sk-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --sk-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --sk-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --sk-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --sk-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --sk-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --sk-gutter-y: 3rem;
  }

  .g-md-6, .gx-md-6 {
    --sk-gutter-x: 4rem;
  }

  .g-md-6, .gy-md-6 {
    --sk-gutter-y: 4rem;
  }

  .g-md-7, .gx-md-7 {
    --sk-gutter-x: 6rem;
  }

  .g-md-7, .gy-md-7 {
    --sk-gutter-y: 6rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --sk-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --sk-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --sk-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --sk-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --sk-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --sk-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --sk-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --sk-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --sk-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --sk-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --sk-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --sk-gutter-y: 3rem;
  }

  .g-lg-6, .gx-lg-6 {
    --sk-gutter-x: 4rem;
  }

  .g-lg-6, .gy-lg-6 {
    --sk-gutter-y: 4rem;
  }

  .g-lg-7, .gx-lg-7 {
    --sk-gutter-x: 6rem;
  }

  .g-lg-7, .gy-lg-7 {
    --sk-gutter-y: 6rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --sk-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --sk-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --sk-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --sk-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --sk-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --sk-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --sk-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --sk-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --sk-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --sk-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --sk-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --sk-gutter-y: 3rem;
  }

  .g-xl-6, .gx-xl-6 {
    --sk-gutter-x: 4rem;
  }

  .g-xl-6, .gy-xl-6 {
    --sk-gutter-y: 4rem;
  }

  .g-xl-7, .gx-xl-7 {
    --sk-gutter-x: 6rem;
  }

  .g-xl-7, .gy-xl-7 {
    --sk-gutter-y: 6rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --sk-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --sk-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --sk-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --sk-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --sk-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --sk-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --sk-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --sk-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --sk-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --sk-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --sk-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --sk-gutter-y: 3rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --sk-gutter-x: 4rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --sk-gutter-y: 4rem;
  }

  .g-xxl-7, .gx-xxl-7 {
    --sk-gutter-x: 6rem;
  }

  .g-xxl-7, .gy-xxl-7 {
    --sk-gutter-y: 6rem;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + var(--sk-border-width));
  padding-bottom: calc(.375rem + var(--sk-border-width));
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + var(--sk-border-width));
  padding-bottom: calc(.5rem + var(--sk-border-width));
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--sk-border-width));
  padding-bottom: calc(.25rem + var(--sk-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--sk-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: var(--sk-body-color);
  background-color: var(--sk-form-control-bg);
  border: var(--sk-border-width) solid var(--sk-border-color);
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--sk-body-color);
  background-color: var(--sk-form-control-bg);
  border-color: #8b97ad;
  outline: 0;
  box-shadow: 0 0 0 .25rem #162f5a40;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--sk-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--sk-form-control-disabled-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: var(--sk-body-color);
  background-color: var(--sk-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--sk-border-width);
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--sk-secondary-bg);
}

.form-control-plaintext {
  width: 100%;
  color: var(--sk-body-color);
  border: solid #0000;
  border-width: var(--sk-border-width) 0;
  background-color: #0000;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--sk-border-width) * 2));
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--sk-border-width) * 2));
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.125rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + calc(var(--sk-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--sk-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--sk-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + calc(var(--sk-border-width) * 2));
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + calc(var(--sk-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--sk-border-width) * 2));
}

.form-select {
  --sk-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  width: 100%;
  -moz-padding-start: calc(.75rem - 3px);
  color: var(--sk-body-color);
  background-color: var(--sk-form-control-bg);
  background-image: var(--sk-form-select-bg-img), var(--sk-form-select-bg-icon, none);
  border: var(--sk-border-width) solid var(--sk-border-color);
  -webkit-appearance: none;
  appearance: none;
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border-radius: .375rem;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #8b97ad;
  outline: 0;
  box-shadow: 0 0 0 .25rem #162f5a40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--sk-form-control-disabled-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--sk-body-color);
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

[data-bs-theme="dark"] .form-select {
  --sk-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --sk-form-check-bg: var(--sk-form-control-bg);
  width: 1em;
  height: 1em;
  vertical-align: top;
  background-color: var(--sk-form-check-bg);
  background-image: var(--sk-form-check-bg-image);
  border: var(--sk-border-width) solid var(--sk-border-color);
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #8b97ad;
  outline: 0;
  box-shadow: 0 0 0 .25rem #162f5a40;
}

.form-check-input:checked {
  background-color: #162f5a;
  border-color: #162f5a;
}

.form-check-input:checked[type="checkbox"] {
  --sk-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --sk-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --sk-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #162f5a;
  border-color: #162f5a;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --sk-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  background-image: var(--sk-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --sk-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238b97ad'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --sk-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --sk-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #162f5a40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #162f5a40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #162f5a;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b9c1ce;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: var(--sk-tertiary-bg);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #162f5a;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b9c1ce;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: var(--sk-tertiary-bg);
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--sk-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--sk-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating:not(.form-control:disabled):before {
  top: var(--sk-border-width);
  left: var(--sk-border-width);
  width: calc(100% + -1.125em - .5625rem);
  height: 1.875em;
  content: "";
  background-color: var(--sk-form-control-bg);
  border-radius: .375rem;
  position: absolute;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--sk-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--sk-border-width) solid transparent;
  transform-origin: 0 0;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--sk-border-width) 0;
}

.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--sk-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--sk-tertiary-bg);
  border: var(--sk-border-width) solid var(--sk-border-color);
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.125rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--sk-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: var(--sk-success-text);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: var(--sk-success);
  border-radius: var(--sk-border-radius);
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--sk-success);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--sk-success);
  box-shadow: 0 0 0 .25rem rgba(var(--sk-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--sk-success);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --sk-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--sk-success);
  box-shadow: 0 0 0 .25rem rgba(var(--sk-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--sk-success);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--sk-success-text);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--sk-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--sk-success-text);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: var(--sk-danger-text);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: var(--sk-danger);
  border-radius: var(--sk-border-radius);
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--sk-danger);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--sk-danger);
  box-shadow: 0 0 0 .25rem rgba(var(--sk-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--sk-danger);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --sk-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--sk-danger);
  box-shadow: 0 0 0 .25rem rgba(var(--sk-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--sk-danger);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--sk-danger-text);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--sk-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--sk-danger-text);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --sk-btn-padding-x: .75rem;
  --sk-btn-padding-y: .375rem;
  --sk-btn-font-family: ;
  --sk-btn-font-size: 1rem;
  --sk-btn-font-weight: 400;
  --sk-btn-line-height: 1.5;
  --sk-btn-color: #10264c;
  --sk-btn-bg: transparent;
  --sk-btn-border-width: var(--sk-border-width);
  --sk-btn-border-color: transparent;
  --sk-btn-border-radius: .375rem;
  --sk-btn-hover-border-color: transparent;
  --sk-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --sk-btn-disabled-opacity: .65;
  --sk-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--sk-btn-focus-shadow-rgb), .5);
  padding: var(--sk-btn-padding-y) var(--sk-btn-padding-x);
  font-family: var(--sk-btn-font-family);
  font-size: var(--sk-btn-font-size);
  font-weight: var(--sk-btn-font-weight);
  line-height: var(--sk-btn-line-height);
  color: var(--sk-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--sk-btn-border-width) solid var(--sk-btn-border-color);
  border-radius: var(--sk-btn-border-radius);
  background-color: var(--sk-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--sk-btn-hover-color);
  background-color: var(--sk-btn-hover-bg);
  border-color: var(--sk-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--sk-btn-color);
  background-color: var(--sk-btn-bg);
  border-color: var(--sk-btn-border-color);
}

.btn:focus-visible {
  color: var(--sk-btn-hover-color);
  background-color: var(--sk-btn-hover-bg);
  border-color: var(--sk-btn-hover-border-color);
  box-shadow: var(--sk-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--sk-btn-hover-border-color);
  box-shadow: var(--sk-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--sk-btn-active-color);
  background-color: var(--sk-btn-active-bg);
  border-color: var(--sk-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--sk-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--sk-btn-disabled-color);
  pointer-events: none;
  background-color: var(--sk-btn-disabled-bg);
  border-color: var(--sk-btn-disabled-border-color);
  opacity: var(--sk-btn-disabled-opacity);
}

.btn-primary {
  --sk-btn-color: #fff;
  --sk-btn-bg: #162f5a;
  --sk-btn-border-color: #162f5a;
  --sk-btn-hover-color: #fff;
  --sk-btn-hover-bg: #13284d;
  --sk-btn-hover-border-color: #122648;
  --sk-btn-focus-shadow-rgb: 57, 78, 115;
  --sk-btn-active-color: #fff;
  --sk-btn-active-bg: #122648;
  --sk-btn-active-border-color: #112344;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #fff;
  --sk-btn-disabled-bg: #162f5a;
  --sk-btn-disabled-border-color: #162f5a;
}

.btn-orange {
  --sk-btn-color: #000;
  --sk-btn-bg: #fd7e14;
  --sk-btn-border-color: #fd7e14;
  --sk-btn-hover-color: #000;
  --sk-btn-hover-bg: #fd9137;
  --sk-btn-hover-border-color: #fd8b2c;
  --sk-btn-focus-shadow-rgb: 215, 107, 17;
  --sk-btn-active-color: #000;
  --sk-btn-active-bg: #fd9843;
  --sk-btn-active-border-color: #fd8b2c;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #000;
  --sk-btn-disabled-bg: #fd7e14;
  --sk-btn-disabled-border-color: #fd7e14;
}

.btn-light {
  --sk-btn-color: #000;
  --sk-btn-bg: #f8f9fa;
  --sk-btn-border-color: #f8f9fa;
  --sk-btn-hover-color: #000;
  --sk-btn-hover-bg: #d3d4d5;
  --sk-btn-hover-border-color: #c6c7c8;
  --sk-btn-focus-shadow-rgb: 211, 212, 213;
  --sk-btn-active-color: #000;
  --sk-btn-active-bg: #c6c7c8;
  --sk-btn-active-border-color: #babbbc;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #000;
  --sk-btn-disabled-bg: #f8f9fa;
  --sk-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --sk-btn-color: #fff;
  --sk-btn-bg: #10264c;
  --sk-btn-border-color: #10264c;
  --sk-btn-hover-color: #fff;
  --sk-btn-hover-bg: #344767;
  --sk-btn-hover-border-color: #283c5e;
  --sk-btn-focus-shadow-rgb: 52, 71, 103;
  --sk-btn-active-color: #fff;
  --sk-btn-active-bg: #405170;
  --sk-btn-active-border-color: #283c5e;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #fff;
  --sk-btn-disabled-bg: #10264c;
  --sk-btn-disabled-border-color: #10264c;
}

.btn-outline-primary {
  --sk-btn-color: #162f5a;
  --sk-btn-border-color: #162f5a;
  --sk-btn-hover-color: #fff;
  --sk-btn-hover-bg: #162f5a;
  --sk-btn-hover-border-color: #162f5a;
  --sk-btn-focus-shadow-rgb: 22, 47, 90;
  --sk-btn-active-color: #fff;
  --sk-btn-active-bg: #162f5a;
  --sk-btn-active-border-color: #162f5a;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #162f5a;
  --sk-btn-disabled-bg: transparent;
  --sk-btn-disabled-border-color: #162f5a;
  --sk-gradient: none;
}

.btn-outline-orange {
  --sk-btn-color: #fd7e14;
  --sk-btn-border-color: #fd7e14;
  --sk-btn-hover-color: #000;
  --sk-btn-hover-bg: #fd7e14;
  --sk-btn-hover-border-color: #fd7e14;
  --sk-btn-focus-shadow-rgb: 253, 126, 20;
  --sk-btn-active-color: #000;
  --sk-btn-active-bg: #fd7e14;
  --sk-btn-active-border-color: #fd7e14;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #fd7e14;
  --sk-btn-disabled-bg: transparent;
  --sk-btn-disabled-border-color: #fd7e14;
  --sk-gradient: none;
}

.btn-outline-light {
  --sk-btn-color: #f8f9fa;
  --sk-btn-border-color: #f8f9fa;
  --sk-btn-hover-color: #000;
  --sk-btn-hover-bg: #f8f9fa;
  --sk-btn-hover-border-color: #f8f9fa;
  --sk-btn-focus-shadow-rgb: 248, 249, 250;
  --sk-btn-active-color: #000;
  --sk-btn-active-bg: #f8f9fa;
  --sk-btn-active-border-color: #f8f9fa;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #f8f9fa;
  --sk-btn-disabled-bg: transparent;
  --sk-btn-disabled-border-color: #f8f9fa;
  --sk-gradient: none;
}

.btn-outline-dark {
  --sk-btn-color: #10264c;
  --sk-btn-border-color: #10264c;
  --sk-btn-hover-color: #fff;
  --sk-btn-hover-bg: #10264c;
  --sk-btn-hover-border-color: #10264c;
  --sk-btn-focus-shadow-rgb: 16, 38, 76;
  --sk-btn-active-color: #fff;
  --sk-btn-active-bg: #10264c;
  --sk-btn-active-border-color: #10264c;
  --sk-btn-active-shadow: inset 0 3px 5px #00000020;
  --sk-btn-disabled-color: #10264c;
  --sk-btn-disabled-bg: transparent;
  --sk-btn-disabled-border-color: #10264c;
  --sk-gradient: none;
}

.btn-link {
  --sk-btn-font-weight: 400;
  --sk-btn-color: var(--sk-link-color);
  --sk-btn-bg: transparent;
  --sk-btn-border-color: transparent;
  --sk-btn-hover-color: var(--sk-link-hover-color);
  --sk-btn-hover-border-color: transparent;
  --sk-btn-active-color: var(--sk-link-hover-color);
  --sk-btn-active-border-color: transparent;
  --sk-btn-disabled-color: #6c757d;
  --sk-btn-disabled-border-color: transparent;
  --sk-btn-box-shadow: none;
  --sk-btn-focus-shadow-rgb: 57, 78, 115;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--sk-btn-color);
}

.btn-link:hover {
  color: var(--sk-btn-hover-color);
}

.btn-lg {
  --sk-btn-padding-y: .5rem;
  --sk-btn-padding-x: 1rem;
  --sk-btn-font-size: 1.125rem;
  --sk-btn-border-radius: .5rem;
}

.btn-sm {
  --sk-btn-padding-y: .25rem;
  --sk-btn-padding-x: .5rem;
  --sk-btn-font-size: .875rem;
  --sk-btn-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --sk-dropdown-zindex: 1000;
  --sk-dropdown-min-width: 10rem;
  --sk-dropdown-padding-x: 0;
  --sk-dropdown-padding-y: .5rem;
  --sk-dropdown-spacer: .125rem;
  --sk-dropdown-font-size: 1rem;
  --sk-dropdown-color: var(--sk-body-color);
  --sk-dropdown-bg: var(--sk-body-bg);
  --sk-dropdown-border-color: var(--sk-border-color-translucent);
  --sk-dropdown-border-radius: .375rem;
  --sk-dropdown-border-width: var(--sk-border-width);
  --sk-dropdown-inner-border-radius: calc(.375rem - var(--sk-border-width));
  --sk-dropdown-divider-bg: var(--sk-border-color-translucent);
  --sk-dropdown-divider-margin-y: .5rem;
  --sk-dropdown-box-shadow: 0 .5rem 1rem rgba(var(--sk-body-color-rgb), .15);
  --sk-dropdown-link-color: var(--sk-body-color);
  --sk-dropdown-link-hover-color: var(--sk-body-color);
  --sk-dropdown-link-hover-bg: var(--sk-tertiary-bg);
  --sk-dropdown-link-active-color: #fff;
  --sk-dropdown-link-active-bg: #162f5a;
  --sk-dropdown-link-disabled-color: #adb5bd;
  --sk-dropdown-item-padding-x: 1rem;
  --sk-dropdown-item-padding-y: .25rem;
  --sk-dropdown-header-color: #6c757d;
  --sk-dropdown-header-padding-x: 1rem;
  --sk-dropdown-header-padding-y: .5rem;
  z-index: var(--sk-dropdown-zindex);
  min-width: var(--sk-dropdown-min-width);
  padding: var(--sk-dropdown-padding-y) var(--sk-dropdown-padding-x);
  font-size: var(--sk-dropdown-font-size);
  color: var(--sk-dropdown-color);
  text-align: left;
  background-color: var(--sk-dropdown-bg);
  border: var(--sk-dropdown-border-width) solid var(--sk-dropdown-border-color);
  border-radius: var(--sk-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--sk-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--sk-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--sk-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--sk-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--sk-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--sk-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--sk-dropdown-item-padding-y) var(--sk-dropdown-item-padding-x);
  clear: both;
  color: var(--sk-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--sk-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--sk-dropdown-link-hover-color);
  background-color: var(--sk-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--sk-dropdown-link-active-color);
  background-color: var(--sk-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--sk-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--sk-dropdown-header-padding-y) var(--sk-dropdown-header-padding-x);
  color: var(--sk-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--sk-dropdown-item-padding-y) var(--sk-dropdown-item-padding-x);
  color: var(--sk-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --sk-dropdown-color: #dee2e6;
  --sk-dropdown-bg: #343a40;
  --sk-dropdown-border-color: var(--sk-border-color-translucent);
  --sk-dropdown-box-shadow: ;
  --sk-dropdown-link-color: #dee2e6;
  --sk-dropdown-link-hover-color: #fff;
  --sk-dropdown-divider-bg: var(--sk-border-color-translucent);
  --sk-dropdown-link-hover-bg: #ffffff26;
  --sk-dropdown-link-active-color: #fff;
  --sk-dropdown-link-active-bg: #162f5a;
  --sk-dropdown-link-disabled-color: #adb5bd;
  --sk-dropdown-header-color: #adb5bd;
}

.nav {
  --sk-nav-link-padding-x: 1rem;
  --sk-nav-link-padding-y: .5rem;
  --sk-nav-link-font-weight: ;
  --sk-nav-link-color: var(--sk-link-color);
  --sk-nav-link-hover-color: var(--sk-link-hover-color);
  --sk-nav-link-disabled-color: var(--sk-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--sk-nav-link-padding-y) var(--sk-nav-link-padding-x);
  font-size: var(--sk-nav-link-font-size);
  font-weight: var(--sk-nav-link-font-weight);
  color: var(--sk-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--sk-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--sk-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --sk-nav-tabs-border-width: var(--sk-border-width);
  --sk-nav-tabs-border-color: var(--sk-border-color);
  --sk-nav-tabs-border-radius: var(--sk-border-radius);
  --sk-nav-tabs-link-hover-border-color: var(--sk-secondary-bg) var(--sk-secondary-bg) var(--sk-border-color);
  --sk-nav-tabs-link-active-color: var(--sk-emphasis-color);
  --sk-nav-tabs-link-active-bg: var(--sk-body-bg);
  --sk-nav-tabs-link-active-border-color: var(--sk-border-color) var(--sk-border-color) var(--sk-body-bg);
  border-bottom: var(--sk-nav-tabs-border-width) solid var(--sk-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--sk-nav-tabs-border-width));
  border: var(--sk-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--sk-nav-tabs-border-radius);
  border-top-right-radius: var(--sk-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--sk-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--sk-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--sk-nav-tabs-link-active-color);
  background-color: var(--sk-nav-tabs-link-active-bg);
  border-color: var(--sk-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--sk-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --sk-nav-pills-border-radius: .375rem;
  --sk-nav-pills-link-active-color: #fff;
  --sk-nav-pills-link-active-bg: #162f5a;
}

.nav-pills .nav-link {
  border-radius: var(--sk-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--sk-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--sk-nav-pills-link-active-color);
  background-color: var(--sk-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --sk-navbar-padding-x: 0;
  --sk-navbar-padding-y: .5rem;
  --sk-navbar-color: rgba(var(--sk-emphasis-color-rgb), .65);
  --sk-navbar-hover-color: rgba(var(--sk-emphasis-color-rgb), .8);
  --sk-navbar-disabled-color: rgba(var(--sk-emphasis-color-rgb), .3);
  --sk-navbar-active-color: rgba(var(--sk-emphasis-color-rgb), 1);
  --sk-navbar-brand-padding-y: .40625rem;
  --sk-navbar-brand-margin-end: 1rem;
  --sk-navbar-brand-font-size: 1.125rem;
  --sk-navbar-brand-color: rgba(var(--sk-emphasis-color-rgb), 1);
  --sk-navbar-brand-hover-color: rgba(var(--sk-emphasis-color-rgb), 1);
  --sk-navbar-nav-link-padding-x: .5rem;
  --sk-navbar-toggler-padding-y: .25rem;
  --sk-navbar-toggler-padding-x: .75rem;
  --sk-navbar-toggler-font-size: 1.125rem;
  --sk-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2816, 38, 76, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --sk-navbar-toggler-border-color: rgba(var(--sk-emphasis-color-rgb), .15);
  --sk-navbar-toggler-border-radius: .375rem;
  --sk-navbar-toggler-focus-width: .25rem;
  --sk-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--sk-navbar-padding-y) var(--sk-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--sk-navbar-brand-padding-y);
  padding-bottom: var(--sk-navbar-brand-padding-y);
  margin-right: var(--sk-navbar-brand-margin-end);
  font-size: var(--sk-navbar-brand-font-size);
  color: var(--sk-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--sk-navbar-brand-hover-color);
}

.navbar-nav {
  --sk-nav-link-padding-x: 0;
  --sk-nav-link-padding-y: .5rem;
  --sk-nav-link-font-weight: ;
  --sk-nav-link-color: var(--sk-navbar-color);
  --sk-nav-link-hover-color: var(--sk-navbar-hover-color);
  --sk-nav-link-disabled-color: var(--sk-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--sk-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--sk-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--sk-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--sk-navbar-toggler-padding-y) var(--sk-navbar-toggler-padding-x);
  font-size: var(--sk-navbar-toggler-font-size);
  color: var(--sk-navbar-color);
  border: var(--sk-border-width) solid var(--sk-navbar-toggler-border-color);
  border-radius: var(--sk-navbar-toggler-border-radius);
  transition: var(--sk-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--sk-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--sk-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--sk-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--sk-navbar-nav-link-padding-x);
    padding-left: var(--sk-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--sk-navbar-nav-link-padding-x);
    padding-left: var(--sk-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--sk-navbar-nav-link-padding-x);
    padding-left: var(--sk-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--sk-navbar-nav-link-padding-x);
    padding-left: var(--sk-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--sk-navbar-nav-link-padding-x);
    padding-left: var(--sk-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--sk-navbar-nav-link-padding-x);
  padding-left: var(--sk-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --sk-navbar-color: #ffffff8c;
  --sk-navbar-hover-color: #ffffffbf;
  --sk-navbar-disabled-color: #ffffff40;
  --sk-navbar-active-color: #fff;
  --sk-navbar-brand-color: #fff;
  --sk-navbar-brand-hover-color: #fff;
  --sk-navbar-toggler-border-color: #ffffff1a;
  --sk-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar {
  --sk-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --sk-card-spacer-y: 1rem;
  --sk-card-spacer-x: 1rem;
  --sk-card-title-spacer-y: .5rem;
  --sk-card-title-color: ;
  --sk-card-subtitle-color: ;
  --sk-card-border-width: var(--sk-border-width);
  --sk-card-border-color: var(--sk-border-color-translucent);
  --sk-card-border-radius: var(--sk-border-radius);
  --sk-card-box-shadow: ;
  --sk-card-inner-border-radius: calc(var(--sk-border-radius)  - (var(--sk-border-width)));
  --sk-card-cap-padding-y: .5rem;
  --sk-card-cap-padding-x: 1rem;
  --sk-card-cap-bg: rgba(var(--sk-body-color-rgb), .03);
  --sk-card-cap-color: ;
  --sk-card-height: ;
  --sk-card-color: ;
  --sk-card-bg: var(--sk-body-bg);
  --sk-card-img-overlay-padding: 1rem;
  --sk-card-group-margin: .75rem;
  min-width: 0;
  height: var(--sk-card-height);
  word-wrap: break-word;
  background-color: var(--sk-card-bg);
  border: var(--sk-card-border-width) solid var(--sk-card-border-color);
  border-radius: var(--sk-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--sk-card-inner-border-radius);
  border-top-right-radius: var(--sk-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--sk-card-inner-border-radius);
  border-bottom-left-radius: var(--sk-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--sk-card-spacer-y) var(--sk-card-spacer-x);
  color: var(--sk-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--sk-card-title-spacer-y);
  color: var(--sk-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--sk-card-title-spacer-y));
  color: var(--sk-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--sk-card-spacer-x);
}

.card-header {
  padding: var(--sk-card-cap-padding-y) var(--sk-card-cap-padding-x);
  color: var(--sk-card-cap-color);
  background-color: var(--sk-card-cap-bg);
  border-bottom: var(--sk-card-border-width) solid var(--sk-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--sk-card-inner-border-radius) var(--sk-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--sk-card-cap-padding-y) var(--sk-card-cap-padding-x);
  color: var(--sk-card-cap-color);
  background-color: var(--sk-card-cap-bg);
  border-top: var(--sk-card-border-width) solid var(--sk-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--sk-card-inner-border-radius) var(--sk-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--sk-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--sk-card-cap-padding-y));
  margin-left: calc(-.5 * var(--sk-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--sk-card-bg);
  border-bottom-color: var(--sk-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--sk-card-cap-padding-x));
  margin-left: calc(-.5 * var(--sk-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--sk-card-img-overlay-padding);
  border-radius: var(--sk-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--sk-card-inner-border-radius);
  border-top-right-radius: var(--sk-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--sk-card-inner-border-radius);
  border-bottom-left-radius: var(--sk-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--sk-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --sk-accordion-color: var(--sk-body-color);
  --sk-accordion-bg: var(--sk-body-bg);
  --sk-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --sk-accordion-border-color: var(--sk-border-color);
  --sk-accordion-border-width: var(--sk-border-width);
  --sk-accordion-border-radius: var(--sk-border-radius);
  --sk-accordion-inner-border-radius: calc(var(--sk-border-radius)  - (var(--sk-border-width)));
  --sk-accordion-btn-padding-x: 1.25rem;
  --sk-accordion-btn-padding-y: 1rem;
  --sk-accordion-btn-color: var(--sk-body-color);
  --sk-accordion-btn-bg: var(--sk-accordion-bg);
  --sk-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2310264c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --sk-accordion-btn-icon-width: 1.25rem;
  --sk-accordion-btn-icon-transform: rotate(-180deg);
  --sk-accordion-btn-icon-transition: transform .2s ease-in-out;
  --sk-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a58ca'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --sk-accordion-btn-focus-border-color: #8b97ad;
  --sk-accordion-btn-focus-box-shadow: 0 0 0 .25rem #162f5a40;
  --sk-accordion-body-padding-x: 1.25rem;
  --sk-accordion-body-padding-y: 1rem;
  --sk-accordion-active-color: var(--sk-primary-text);
  --sk-accordion-active-bg: var(--sk-primary-bg-subtle);
}

.accordion-button {
  width: 100%;
  padding: var(--sk-accordion-btn-padding-y) var(--sk-accordion-btn-padding-x);
  color: var(--sk-accordion-btn-color);
  text-align: left;
  background-color: var(--sk-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--sk-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--sk-accordion-active-color);
  background-color: var(--sk-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--sk-accordion-border-width)) 0 var(--sk-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--sk-accordion-btn-active-icon);
  transform: var(--sk-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--sk-accordion-btn-icon-width);
  height: var(--sk-accordion-btn-icon-width);
  content: "";
  background-image: var(--sk-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--sk-accordion-btn-icon-width);
  transition: var(--sk-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--sk-accordion-btn-focus-border-color);
  box-shadow: var(--sk-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--sk-accordion-color);
  background-color: var(--sk-accordion-bg);
  border: var(--sk-accordion-border-width) solid var(--sk-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--sk-accordion-border-radius);
  border-top-right-radius: var(--sk-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--sk-accordion-inner-border-radius);
  border-top-right-radius: var(--sk-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--sk-accordion-border-radius);
  border-bottom-left-radius: var(--sk-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--sk-accordion-inner-border-radius);
  border-bottom-left-radius: var(--sk-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--sk-accordion-border-radius);
  border-bottom-left-radius: var(--sk-accordion-border-radius);
}

.accordion-body {
  padding: var(--sk-accordion-body-padding-y) var(--sk-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --sk-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --sk-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --sk-breadcrumb-padding-x: 0;
  --sk-breadcrumb-padding-y: 0;
  --sk-breadcrumb-margin-bottom: 1rem;
  --sk-breadcrumb-bg: ;
  --sk-breadcrumb-border-radius: ;
  --sk-breadcrumb-divider-color: var(--sk-secondary-color);
  --sk-breadcrumb-item-padding-x: .5rem;
  --sk-breadcrumb-item-active-color: var(--sk-secondary-color);
  padding: var(--sk-breadcrumb-padding-y) var(--sk-breadcrumb-padding-x);
  margin-bottom: var(--sk-breadcrumb-margin-bottom);
  font-size: var(--sk-breadcrumb-font-size);
  background-color: var(--sk-breadcrumb-bg);
  border-radius: var(--sk-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--sk-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--sk-breadcrumb-item-padding-x);
  color: var(--sk-breadcrumb-divider-color);
  content: var(--sk-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--sk-breadcrumb-item-active-color);
}

.pagination {
  --sk-pagination-padding-x: .75rem;
  --sk-pagination-padding-y: .375rem;
  --sk-pagination-font-size: 1rem;
  --sk-pagination-color: var(--sk-link-color);
  --sk-pagination-bg: var(--sk-body-bg);
  --sk-pagination-border-width: var(--sk-border-width);
  --sk-pagination-border-color: var(--sk-border-color);
  --sk-pagination-border-radius: var(--sk-border-radius);
  --sk-pagination-hover-color: var(--sk-link-hover-color);
  --sk-pagination-hover-bg: var(--sk-tertiary-bg);
  --sk-pagination-hover-border-color: var(--sk-border-color);
  --sk-pagination-focus-color: var(--sk-link-hover-color);
  --sk-pagination-focus-bg: var(--sk-secondary-bg);
  --sk-pagination-focus-box-shadow: 0 0 0 .25rem #162f5a40;
  --sk-pagination-active-color: #fff;
  --sk-pagination-active-bg: #162f5a;
  --sk-pagination-active-border-color: #162f5a;
  --sk-pagination-disabled-color: var(--sk-secondary-color);
  --sk-pagination-disabled-bg: var(--sk-secondary-bg);
  --sk-pagination-disabled-border-color: var(--sk-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--sk-pagination-padding-y) var(--sk-pagination-padding-x);
  font-size: var(--sk-pagination-font-size);
  color: var(--sk-pagination-color);
  background-color: var(--sk-pagination-bg);
  border: var(--sk-pagination-border-width) solid var(--sk-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--sk-pagination-hover-color);
  background-color: var(--sk-pagination-hover-bg);
  border-color: var(--sk-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--sk-pagination-focus-color);
  background-color: var(--sk-pagination-focus-bg);
  box-shadow: var(--sk-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--sk-pagination-active-color);
  background-color: var(--sk-pagination-active-bg);
  border-color: var(--sk-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--sk-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--sk-pagination-disabled-bg);
  border-color: var(--sk-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--sk-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--sk-pagination-border-radius);
  border-bottom-left-radius: var(--sk-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--sk-pagination-border-radius);
  border-bottom-right-radius: var(--sk-pagination-border-radius);
}

.pagination-lg {
  --sk-pagination-padding-x: 1.5rem;
  --sk-pagination-padding-y: .75rem;
  --sk-pagination-font-size: 1.125rem;
  --sk-pagination-border-radius: .5rem;
}

.pagination-sm {
  --sk-pagination-padding-x: .5rem;
  --sk-pagination-padding-y: .25rem;
  --sk-pagination-font-size: .875rem;
  --sk-pagination-border-radius: .25rem;
}

.list-group {
  --sk-list-group-color: var(--sk-body-color);
  --sk-list-group-bg: var(--sk-body-bg);
  --sk-list-group-border-color: var(--sk-border-color);
  --sk-list-group-border-width: var(--sk-border-width);
  --sk-list-group-border-radius: var(--sk-border-radius);
  --sk-list-group-item-padding-x: 1rem;
  --sk-list-group-item-padding-y: .5rem;
  --sk-list-group-action-color: var(--sk-secondary-color);
  --sk-list-group-action-hover-color: var(--sk-emphasis-color);
  --sk-list-group-action-hover-bg: var(--sk-tertiary-bg);
  --sk-list-group-action-active-color: var(--sk-body-color);
  --sk-list-group-action-active-bg: var(--sk-secondary-bg);
  --sk-list-group-disabled-color: var(--sk-secondary-color);
  --sk-list-group-disabled-bg: var(--sk-body-bg);
  --sk-list-group-active-color: #fff;
  --sk-list-group-active-bg: #162f5a;
  --sk-list-group-active-border-color: #162f5a;
  border-radius: var(--sk-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--sk-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--sk-list-group-action-hover-color);
  background-color: var(--sk-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--sk-list-group-action-active-color);
  background-color: var(--sk-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--sk-list-group-item-padding-y) var(--sk-list-group-item-padding-x);
  color: var(--sk-list-group-color);
  background-color: var(--sk-list-group-bg);
  border: var(--sk-list-group-border-width) solid var(--sk-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--sk-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--sk-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--sk-list-group-active-color);
  background-color: var(--sk-list-group-active-bg);
  border-color: var(--sk-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--sk-list-group-border-width));
  border-top-width: var(--sk-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--sk-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--sk-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--sk-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--sk-list-group-border-width));
  border-left-width: var(--sk-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--sk-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--sk-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--sk-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--sk-list-group-border-width));
    border-left-width: var(--sk-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--sk-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--sk-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--sk-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--sk-list-group-border-width));
    border-left-width: var(--sk-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--sk-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--sk-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--sk-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--sk-list-group-border-width));
    border-left-width: var(--sk-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--sk-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--sk-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--sk-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--sk-list-group-border-width));
    border-left-width: var(--sk-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--sk-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--sk-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--sk-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--sk-list-group-border-width));
    border-left-width: var(--sk-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--sk-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --sk-list-group-color: var(--sk-primary-text);
  --sk-list-group-bg: var(--sk-primary-bg-subtle);
  --sk-list-group-border-color: var(--sk-primary-border-subtle);
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  --sk-list-group-action-hover-color: var(--sk-emphasis-color);
  --sk-list-group-action-hover-bg: var(--sk-primary-border-subtle);
}

.list-group-item-primary.list-group-item-action:active {
  --sk-list-group-active-color: var(--sk-emphasis-color);
  --sk-list-group-active-bg: var(--sk-primary-text);
  --sk-list-group-active-border-color: var(--sk-primary-text);
}

.list-group-item-orange {
  --sk-list-group-color: var(--sk-orange-text);
  --sk-list-group-bg: var(--sk-orange-bg-subtle);
  --sk-list-group-border-color: var(--sk-orange-border-subtle);
}

.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  --sk-list-group-action-hover-color: var(--sk-emphasis-color);
  --sk-list-group-action-hover-bg: var(--sk-orange-border-subtle);
}

.list-group-item-orange.list-group-item-action:active {
  --sk-list-group-active-color: var(--sk-emphasis-color);
  --sk-list-group-active-bg: var(--sk-orange-text);
  --sk-list-group-active-border-color: var(--sk-orange-text);
}

.list-group-item-light {
  --sk-list-group-color: var(--sk-light-text);
  --sk-list-group-bg: var(--sk-light-bg-subtle);
  --sk-list-group-border-color: var(--sk-light-border-subtle);
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  --sk-list-group-action-hover-color: var(--sk-emphasis-color);
  --sk-list-group-action-hover-bg: var(--sk-light-border-subtle);
}

.list-group-item-light.list-group-item-action:active {
  --sk-list-group-active-color: var(--sk-emphasis-color);
  --sk-list-group-active-bg: var(--sk-light-text);
  --sk-list-group-active-border-color: var(--sk-light-text);
}

.list-group-item-dark {
  --sk-list-group-color: var(--sk-dark-text);
  --sk-list-group-bg: var(--sk-dark-bg-subtle);
  --sk-list-group-border-color: var(--sk-dark-border-subtle);
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  --sk-list-group-action-hover-color: var(--sk-emphasis-color);
  --sk-list-group-action-hover-bg: var(--sk-dark-border-subtle);
}

.list-group-item-dark.list-group-item-action:active {
  --sk-list-group-active-color: var(--sk-emphasis-color);
  --sk-list-group-active-bg: var(--sk-dark-text);
  --sk-list-group-active-border-color: var(--sk-dark-text);
}

.btn-close {
  --sk-btn-close-color: #000;
  --sk-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --sk-btn-close-opacity: .5;
  --sk-btn-close-hover-opacity: .75;
  --sk-btn-close-focus-shadow: 0 0 0 .25rem #162f5a40;
  --sk-btn-close-focus-opacity: 1;
  --sk-btn-close-disabled-opacity: .25;
  --sk-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: var(--sk-btn-close-color);
  background: transparent var(--sk-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--sk-btn-close-opacity);
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.btn-close:hover {
  color: var(--sk-btn-close-color);
  opacity: var(--sk-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--sk-btn-close-focus-shadow);
  opacity: var(--sk-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: var(--sk-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--sk-btn-close-white-filter);
}

.tooltip {
  --sk-tooltip-zindex: 1080;
  --sk-tooltip-max-width: 200px;
  --sk-tooltip-padding-x: .5rem;
  --sk-tooltip-padding-y: .25rem;
  --sk-tooltip-margin: ;
  --sk-tooltip-font-size: .875rem;
  --sk-tooltip-color: var(--sk-body-bg);
  --sk-tooltip-bg: var(--sk-emphasis-color);
  --sk-tooltip-border-radius: var(--sk-border-radius);
  --sk-tooltip-opacity: .9;
  --sk-tooltip-arrow-width: .8rem;
  --sk-tooltip-arrow-height: .4rem;
  z-index: var(--sk-tooltip-zindex);
  padding: var(--sk-tooltip-arrow-height);
  margin: var(--sk-tooltip-margin);
  font-family: var(--sk-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--sk-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--sk-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--sk-tooltip-arrow-width);
  height: var(--sk-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--sk-tooltip-arrow-height) calc(var(--sk-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--sk-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--sk-tooltip-arrow-height);
  height: var(--sk-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--sk-tooltip-arrow-width) * .5) var(--sk-tooltip-arrow-height) calc(var(--sk-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--sk-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--sk-tooltip-arrow-width) * .5) var(--sk-tooltip-arrow-height);
  border-bottom-color: var(--sk-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--sk-tooltip-arrow-height);
  height: var(--sk-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--sk-tooltip-arrow-width) * .5) 0 calc(var(--sk-tooltip-arrow-width) * .5) var(--sk-tooltip-arrow-height);
  border-left-color: var(--sk-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--sk-tooltip-max-width);
  padding: var(--sk-tooltip-padding-y) var(--sk-tooltip-padding-x);
  color: var(--sk-tooltip-color);
  text-align: center;
  background-color: var(--sk-tooltip-bg);
  border-radius: var(--sk-tooltip-border-radius);
}

.popover {
  --sk-popover-zindex: 1070;
  --sk-popover-max-width: 276px;
  --sk-popover-font-size: .875rem;
  --sk-popover-bg: var(--sk-body-bg);
  --sk-popover-border-width: var(--sk-border-width);
  --sk-popover-border-color: var(--sk-border-color-translucent);
  --sk-popover-border-radius: var(--sk-border-radius-lg);
  --sk-popover-inner-border-radius: calc(var(--sk-border-radius-lg)  - var(--sk-border-width));
  --sk-popover-box-shadow: 0 .5rem 1rem rgba(var(--sk-body-color-rgb), .15);
  --sk-popover-header-padding-x: 1rem;
  --sk-popover-header-padding-y: .5rem;
  --sk-popover-header-font-size: 1rem;
  --sk-popover-header-color: ;
  --sk-popover-header-bg: var(--sk-secondary-bg);
  --sk-popover-body-padding-x: 1rem;
  --sk-popover-body-padding-y: 1rem;
  --sk-popover-body-color: var(--sk-body-color);
  --sk-popover-arrow-width: 1rem;
  --sk-popover-arrow-height: .5rem;
  --sk-popover-arrow-border: var(--sk-popover-border-color);
  z-index: var(--sk-popover-zindex);
  max-width: var(--sk-popover-max-width);
  font-family: var(--sk-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--sk-popover-font-size);
  word-wrap: break-word;
  background-color: var(--sk-popover-bg);
  border: var(--sk-popover-border-width) solid var(--sk-popover-border-color);
  border-radius: var(--sk-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--sk-popover-arrow-width);
  height: var(--sk-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--sk-popover-arrow-height))  - var(--sk-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--sk-popover-arrow-height) calc(var(--sk-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--sk-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--sk-popover-border-width);
  border-top-color: var(--sk-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--sk-popover-arrow-height))  - var(--sk-popover-border-width));
  width: var(--sk-popover-arrow-height);
  height: var(--sk-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--sk-popover-arrow-width) * .5) var(--sk-popover-arrow-height) calc(var(--sk-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--sk-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--sk-popover-border-width);
  border-right-color: var(--sk-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--sk-popover-arrow-height))  - var(--sk-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--sk-popover-arrow-width) * .5) var(--sk-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--sk-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--sk-popover-border-width);
  border-bottom-color: var(--sk-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--sk-popover-arrow-width);
  margin-left: calc(-.5 * var(--sk-popover-arrow-width));
  content: "";
  border-bottom: var(--sk-popover-border-width) solid var(--sk-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--sk-popover-arrow-height))  - var(--sk-popover-border-width));
  width: var(--sk-popover-arrow-height);
  height: var(--sk-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--sk-popover-arrow-width) * .5) 0 calc(var(--sk-popover-arrow-width) * .5) var(--sk-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--sk-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--sk-popover-border-width);
  border-left-color: var(--sk-popover-bg);
}

.popover-header {
  padding: var(--sk-popover-header-padding-y) var(--sk-popover-header-padding-x);
  font-size: var(--sk-popover-header-font-size);
  color: var(--sk-popover-header-color);
  background-color: var(--sk-popover-header-bg);
  border-bottom: var(--sk-popover-border-width) solid var(--sk-popover-border-color);
  border-top-left-radius: var(--sk-popover-inner-border-radius);
  border-top-right-radius: var(--sk-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--sk-popover-body-padding-y) var(--sk-popover-body-padding-x);
  color: var(--sk-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption {
  color: #000;
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --sk-offcanvas-zindex: 1045;
  --sk-offcanvas-width: 400px;
  --sk-offcanvas-height: 30vh;
  --sk-offcanvas-padding-x: 1rem;
  --sk-offcanvas-padding-y: 1rem;
  --sk-offcanvas-color: var(--sk-body-color);
  --sk-offcanvas-bg: var(--sk-body-bg);
  --sk-offcanvas-border-width: var(--sk-border-width);
  --sk-offcanvas-border-color: var(--sk-border-color-translucent);
  --sk-offcanvas-box-shadow: 0 .125rem .25rem rgba(var(--sk-body-color-rgb), .075);
  --sk-offcanvas-transition: transform .3s ease-in-out;
  --sk-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: var(--sk-offcanvas-zindex);
    max-width: 100%;
    color: var(--sk-offcanvas-color);
    visibility: hidden;
    background-color: var(--sk-offcanvas-bg);
    transition: var(--sk-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--sk-offcanvas-width);
    border-right: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--sk-offcanvas-width);
    border-left: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-top: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --sk-offcanvas-height: auto;
    --sk-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: var(--sk-offcanvas-zindex);
    max-width: 100%;
    color: var(--sk-offcanvas-color);
    visibility: hidden;
    background-color: var(--sk-offcanvas-bg);
    transition: var(--sk-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--sk-offcanvas-width);
    border-right: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--sk-offcanvas-width);
    border-left: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-top: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --sk-offcanvas-height: auto;
    --sk-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: var(--sk-offcanvas-zindex);
    max-width: 100%;
    color: var(--sk-offcanvas-color);
    visibility: hidden;
    background-color: var(--sk-offcanvas-bg);
    transition: var(--sk-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--sk-offcanvas-width);
    border-right: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--sk-offcanvas-width);
    border-left: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-top: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --sk-offcanvas-height: auto;
    --sk-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: var(--sk-offcanvas-zindex);
    max-width: 100%;
    color: var(--sk-offcanvas-color);
    visibility: hidden;
    background-color: var(--sk-offcanvas-bg);
    transition: var(--sk-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--sk-offcanvas-width);
    border-right: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--sk-offcanvas-width);
    border-left: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-top: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --sk-offcanvas-height: auto;
    --sk-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--sk-offcanvas-zindex);
    max-width: 100%;
    color: var(--sk-offcanvas-color);
    visibility: hidden;
    background-color: var(--sk-offcanvas-bg);
    transition: var(--sk-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--sk-offcanvas-width);
    border-right: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--sk-offcanvas-width);
    border-left: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--sk-offcanvas-height);
    max-height: 100%;
    border-top: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --sk-offcanvas-height: auto;
    --sk-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--sk-offcanvas-zindex);
  max-width: 100%;
  color: var(--sk-offcanvas-color);
  visibility: hidden;
  background-color: var(--sk-offcanvas-bg);
  transition: var(--sk-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--sk-offcanvas-width);
  border-right: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--sk-offcanvas-width);
  border-left: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--sk-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--sk-offcanvas-height);
  max-height: 100%;
  border-top: var(--sk-offcanvas-border-width) solid var(--sk-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--sk-offcanvas-padding-y) var(--sk-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--sk-offcanvas-padding-y) * .5) calc(var(--sk-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--sk-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--sk-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--sk-offcanvas-padding-y));
}

.offcanvas-title {
  line-height: var(--sk-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--sk-offcanvas-padding-y) var(--sk-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(22, 47, 90, var(--sk-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #000 !important;
  background-color: RGBA(253, 126, 20, var(--sk-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--sk-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(16, 38, 76, var(--sk-bg-opacity, 1)) !important;
}

.link-primary {
  color: #162f5a !important;
}

.link-primary:hover, .link-primary:focus {
  color: #122648 !important;
}

.link-orange {
  color: #fd7e14 !important;
}

.link-orange:hover, .link-orange:focus {
  color: #fd9843 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #10264c !important;
}

.link-dark:hover, .link-dark:focus {
  color: #0d1e3d !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--sk-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --sk-aspect-ratio: 100%;
}

.ratio-4x3 {
  --sk-aspect-ratio: 75%;
}

.ratio-16x9 {
  --sk-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --sk-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(var(--sk-body-color-rgb), .15) !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(var(--sk-body-color-rgb), .075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(var(--sk-body-color-rgb), .175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --sk-border-opacity: 1;
  border-color: rgba(var(--sk-primary-rgb), var(--sk-border-opacity)) !important;
}

.border-orange {
  --sk-border-opacity: 1;
  border-color: rgba(var(--sk-orange-rgb), var(--sk-border-opacity)) !important;
}

.border-light {
  --sk-border-opacity: 1;
  border-color: rgba(var(--sk-light-rgb), var(--sk-border-opacity)) !important;
}

.border-dark {
  --sk-border-opacity: 1;
  border-color: rgba(var(--sk-dark-rgb), var(--sk-border-opacity)) !important;
}

.border-white {
  --sk-border-opacity: 1;
  border-color: rgba(var(--sk-white-rgb), var(--sk-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--sk-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--sk-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--sk-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--sk-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--sk-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--sk-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--sk-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--sk-dark-border-subtle) !important;
}

.border-1 {
  --sk-border-width: 1px;
}

.border-2 {
  --sk-border-width: 2px;
}

.border-3 {
  --sk-border-width: 3px;
}

.border-4 {
  --sk-border-width: 4px;
}

.border-5 {
  --sk-border-width: 5px;
}

.border-opacity-10 {
  --sk-border-opacity: .1;
}

.border-opacity-25 {
  --sk-border-opacity: .25;
}

.border-opacity-50 {
  --sk-border-opacity: .5;
}

.border-opacity-75 {
  --sk-border-opacity: .75;
}

.border-opacity-100 {
  --sk-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-6 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-7 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4rem !important;
}

.me-7 {
  margin-right: 6rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4rem !important;
}

.ms-7 {
  margin-left: 6rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-6 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-7 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4rem !important;
}

.pe-7 {
  padding-right: 6rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4rem !important;
}

.ps-7 {
  padding-left: 6rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4rem !important;
}

.gap-7 {
  gap: 6rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 4rem !important;
}

.row-gap-7 {
  row-gap: 6rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 4rem !important;
}

.column-gap-7 {
  column-gap: 6rem !important;
}

.font-monospace {
  font-family: var(--sk-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xs {
  font-size: .75rem !important;
}

.fs-sm {
  font-size: .875rem !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fs-xl {
  font-size: 1.25rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --sk-text-opacity: 1;
  color: rgba(var(--sk-primary-rgb), var(--sk-text-opacity)) !important;
}

.text-orange {
  --sk-text-opacity: 1;
  color: rgba(var(--sk-orange-rgb), var(--sk-text-opacity)) !important;
}

.text-light {
  --sk-text-opacity: 1;
  color: rgba(var(--sk-light-rgb), var(--sk-text-opacity)) !important;
}

.text-dark {
  --sk-text-opacity: 1;
  color: rgba(var(--sk-dark-rgb), var(--sk-text-opacity)) !important;
}

.text-black {
  --sk-text-opacity: 1;
  color: rgba(var(--sk-black-rgb), var(--sk-text-opacity)) !important;
}

.text-white {
  --sk-text-opacity: 1;
  color: rgba(var(--sk-white-rgb), var(--sk-text-opacity)) !important;
}

.text-body {
  --sk-text-opacity: 1;
  color: rgba(var(--sk-body-color-rgb), var(--sk-text-opacity)) !important;
}

.text-muted {
  --sk-text-opacity: 1;
  color: var(--sk-secondary-color) !important;
}

.text-black-50 {
  --sk-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --sk-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --sk-text-opacity: 1;
  color: var(--sk-secondary-color) !important;
}

.text-body-tertiary {
  --sk-text-opacity: 1;
  color: var(--sk-tertiary-color) !important;
}

.text-body-emphasis {
  --sk-text-opacity: 1;
  color: var(--sk-emphasis-color) !important;
}

.text-reset {
  --sk-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --sk-text-opacity: .25;
}

.text-opacity-50 {
  --sk-text-opacity: .5;
}

.text-opacity-75 {
  --sk-text-opacity: .75;
}

.text-opacity-100 {
  --sk-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--sk-primary-text) !important;
}

.text-secondary-emphasis {
  color: var(--sk-secondary-text) !important;
}

.text-success-emphasis {
  color: var(--sk-success-text) !important;
}

.text-info-emphasis {
  color: var(--sk-info-text) !important;
}

.text-warning-emphasis {
  color: var(--sk-warning-text) !important;
}

.text-danger-emphasis {
  color: var(--sk-danger-text) !important;
}

.text-light-emphasis {
  color: var(--sk-light-text) !important;
}

.text-dark-emphasis {
  color: var(--sk-dark-text) !important;
}

.bg-primary {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-primary-rgb), var(--sk-bg-opacity)) !important;
}

.bg-orange {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-orange-rgb), var(--sk-bg-opacity)) !important;
}

.bg-light {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-light-rgb), var(--sk-bg-opacity)) !important;
}

.bg-dark {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-dark-rgb), var(--sk-bg-opacity)) !important;
}

.bg-black {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-black-rgb), var(--sk-bg-opacity)) !important;
}

.bg-white {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-white-rgb), var(--sk-bg-opacity)) !important;
}

.bg-body {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-body-bg-rgb), var(--sk-bg-opacity)) !important;
}

.bg-transparent {
  --sk-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-secondary-bg-rgb), var(--sk-bg-opacity)) !important;
}

.bg-body-tertiary {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-tertiary-bg-rgb), var(--sk-bg-opacity)) !important;
}

.bg-body-emphasis {
  --sk-bg-opacity: 1;
  background-color: rgba(var(--sk-emphasis-bg-rgb), var(--sk-bg-opacity)) !important;
}

.bg-opacity-10 {
  --sk-bg-opacity: .1;
}

.bg-opacity-25 {
  --sk-bg-opacity: .25;
}

.bg-opacity-50 {
  --sk-bg-opacity: .5;
}

.bg-opacity-75 {
  --sk-bg-opacity: .75;
}

.bg-opacity-100 {
  --sk-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--sk-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--sk-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--sk-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--sk-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--sk-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--sk-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--sk-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--sk-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--sk-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--sk-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--sk-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--sk-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--sk-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--sk-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--sk-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--sk-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--sk-border-radius) !important;
  border-top-right-radius: var(--sk-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--sk-border-radius-sm) !important;
  border-top-right-radius: var(--sk-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--sk-border-radius) !important;
  border-top-right-radius: var(--sk-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--sk-border-radius-lg) !important;
  border-top-right-radius: var(--sk-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--sk-border-radius-xl) !important;
  border-top-right-radius: var(--sk-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--sk-border-radius-2xl) !important;
  border-top-right-radius: var(--sk-border-radius-2xl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--sk-border-radius-pill) !important;
  border-top-right-radius: var(--sk-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--sk-border-radius) !important;
  border-bottom-right-radius: var(--sk-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--sk-border-radius-sm) !important;
  border-bottom-right-radius: var(--sk-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--sk-border-radius) !important;
  border-bottom-right-radius: var(--sk-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--sk-border-radius-lg) !important;
  border-bottom-right-radius: var(--sk-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--sk-border-radius-xl) !important;
  border-bottom-right-radius: var(--sk-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--sk-border-radius-2xl) !important;
  border-bottom-right-radius: var(--sk-border-radius-2xl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--sk-border-radius-pill) !important;
  border-bottom-right-radius: var(--sk-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--sk-border-radius) !important;
  border-bottom-left-radius: var(--sk-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--sk-border-radius-sm) !important;
  border-bottom-left-radius: var(--sk-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--sk-border-radius) !important;
  border-bottom-left-radius: var(--sk-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--sk-border-radius-lg) !important;
  border-bottom-left-radius: var(--sk-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--sk-border-radius-xl) !important;
  border-bottom-left-radius: var(--sk-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--sk-border-radius-2xl) !important;
  border-bottom-left-radius: var(--sk-border-radius-2xl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--sk-border-radius-pill) !important;
  border-bottom-left-radius: var(--sk-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--sk-border-radius) !important;
  border-top-left-radius: var(--sk-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--sk-border-radius-sm) !important;
  border-top-left-radius: var(--sk-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--sk-border-radius) !important;
  border-top-left-radius: var(--sk-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--sk-border-radius-lg) !important;
  border-top-left-radius: var(--sk-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--sk-border-radius-xl) !important;
  border-top-left-radius: var(--sk-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--sk-border-radius-2xl) !important;
  border-top-left-radius: var(--sk-border-radius-2xl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--sk-border-radius-pill) !important;
  border-top-left-radius: var(--sk-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .border-start-sm {
    border-left: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
  }

  .border-start-sm-0 {
    border-left: 0 !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 4rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-6 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-sm-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 4rem !important;
  }

  .mt-sm-7 {
    margin-top: 6rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 4rem !important;
  }

  .me-sm-7 {
    margin-right: 6rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 4rem !important;
  }

  .ms-sm-7 {
    margin-left: 6rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 4rem !important;
  }

  .p-sm-7 {
    padding: 6rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-6 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-sm-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 4rem !important;
  }

  .pt-sm-7 {
    padding-top: 6rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 4rem !important;
  }

  .pe-sm-7 {
    padding-right: 6rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 4rem !important;
  }

  .ps-sm-7 {
    padding-left: 6rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 4rem !important;
  }

  .gap-sm-7 {
    gap: 6rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 6rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 6rem !important;
  }

  .fs-sm-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-sm-2 {
    font-size: calc(1.325rem + .9vw) !important;
  }

  .fs-sm-3 {
    font-size: calc(1.3rem + .6vw) !important;
  }

  .fs-sm-4 {
    font-size: calc(1.275rem + .3vw) !important;
  }

  .fs-sm-5 {
    font-size: 1.25rem !important;
  }

  .fs-sm-6 {
    font-size: 1rem !important;
  }

  .fs-sm-xs {
    font-size: .75rem !important;
  }

  .fs-sm-sm {
    font-size: .875rem !important;
  }

  .fs-sm-lg {
    font-size: 1.125rem !important;
  }

  .fs-sm-xl {
    font-size: 1.25rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .border-start-md {
    border-left: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
  }

  .border-start-md-0 {
    border-left: 0 !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 4rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-6 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-md-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 4rem !important;
  }

  .mt-md-7 {
    margin-top: 6rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 4rem !important;
  }

  .me-md-7 {
    margin-right: 6rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 4rem !important;
  }

  .mb-md-7 {
    margin-bottom: 6rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 4rem !important;
  }

  .ms-md-7 {
    margin-left: 6rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 4rem !important;
  }

  .p-md-7 {
    padding: 6rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-6 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-md-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 4rem !important;
  }

  .pt-md-7 {
    padding-top: 6rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 4rem !important;
  }

  .pe-md-7 {
    padding-right: 6rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 4rem !important;
  }

  .pb-md-7 {
    padding-bottom: 6rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 4rem !important;
  }

  .ps-md-7 {
    padding-left: 6rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 4rem !important;
  }

  .gap-md-7 {
    gap: 6rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .row-gap-md-6 {
    row-gap: 4rem !important;
  }

  .row-gap-md-7 {
    row-gap: 6rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .column-gap-md-6 {
    column-gap: 4rem !important;
  }

  .column-gap-md-7 {
    column-gap: 6rem !important;
  }

  .fs-md-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-md-2 {
    font-size: calc(1.325rem + .9vw) !important;
  }

  .fs-md-3 {
    font-size: calc(1.3rem + .6vw) !important;
  }

  .fs-md-4 {
    font-size: calc(1.275rem + .3vw) !important;
  }

  .fs-md-5 {
    font-size: 1.25rem !important;
  }

  .fs-md-6 {
    font-size: 1rem !important;
  }

  .fs-md-xs {
    font-size: .75rem !important;
  }

  .fs-md-sm {
    font-size: .875rem !important;
  }

  .fs-md-lg {
    font-size: 1.125rem !important;
  }

  .fs-md-xl {
    font-size: 1.25rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .border-start-lg {
    border-left: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
  }

  .border-start-lg-0 {
    border-left: 0 !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 4rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-6 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-lg-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 4rem !important;
  }

  .mt-lg-7 {
    margin-top: 6rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 4rem !important;
  }

  .me-lg-7 {
    margin-right: 6rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 4rem !important;
  }

  .ms-lg-7 {
    margin-left: 6rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 4rem !important;
  }

  .p-lg-7 {
    padding: 6rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-6 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-lg-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 4rem !important;
  }

  .pt-lg-7 {
    padding-top: 6rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 4rem !important;
  }

  .pe-lg-7 {
    padding-right: 6rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 4rem !important;
  }

  .ps-lg-7 {
    padding-left: 6rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 4rem !important;
  }

  .gap-lg-7 {
    gap: 6rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 6rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 6rem !important;
  }

  .fs-lg-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-lg-2 {
    font-size: calc(1.325rem + .9vw) !important;
  }

  .fs-lg-3 {
    font-size: calc(1.3rem + .6vw) !important;
  }

  .fs-lg-4 {
    font-size: calc(1.275rem + .3vw) !important;
  }

  .fs-lg-5 {
    font-size: 1.25rem !important;
  }

  .fs-lg-6 {
    font-size: 1rem !important;
  }

  .fs-lg-xs {
    font-size: .75rem !important;
  }

  .fs-lg-sm {
    font-size: .875rem !important;
  }

  .fs-lg-lg {
    font-size: 1.125rem !important;
  }

  .fs-lg-xl {
    font-size: 1.25rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .border-start-xl {
    border-left: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
  }

  .border-start-xl-0 {
    border-left: 0 !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 4rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-6 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 4rem !important;
  }

  .mt-xl-7 {
    margin-top: 6rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 4rem !important;
  }

  .me-xl-7 {
    margin-right: 6rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 4rem !important;
  }

  .ms-xl-7 {
    margin-left: 6rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 4rem !important;
  }

  .p-xl-7 {
    padding: 6rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-6 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 4rem !important;
  }

  .pt-xl-7 {
    padding-top: 6rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 4rem !important;
  }

  .pe-xl-7 {
    padding-right: 6rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 4rem !important;
  }

  .ps-xl-7 {
    padding-left: 6rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 4rem !important;
  }

  .gap-xl-7 {
    gap: 6rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 6rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 6rem !important;
  }

  .fs-xl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xl-2 {
    font-size: calc(1.325rem + .9vw) !important;
  }

  .fs-xl-3 {
    font-size: calc(1.3rem + .6vw) !important;
  }

  .fs-xl-4 {
    font-size: calc(1.275rem + .3vw) !important;
  }

  .fs-xl-5 {
    font-size: 1.25rem !important;
  }

  .fs-xl-6 {
    font-size: 1rem !important;
  }

  .fs-xl-xs {
    font-size: .75rem !important;
  }

  .fs-xl-sm {
    font-size: .875rem !important;
  }

  .fs-xl-lg {
    font-size: 1.125rem !important;
  }

  .fs-xl-xl {
    font-size: 1.25rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .border-start-xxl {
    border-left: var(--sk-border-width) var(--sk-border-style) var(--sk-border-color) !important;
  }

  .border-start-xxl-0 {
    border-left: 0 !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 4rem !important;
  }

  .m-xxl-7 {
    margin: 6rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-6 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xxl-7 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 4rem !important;
  }

  .mt-xxl-7 {
    margin-top: 6rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 4rem !important;
  }

  .me-xxl-7 {
    margin-right: 6rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 4rem !important;
  }

  .ms-xxl-7 {
    margin-left: 6rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 4rem !important;
  }

  .p-xxl-7 {
    padding: 6rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xxl-6 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xxl-7 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 4rem !important;
  }

  .pt-xxl-7 {
    padding-top: 6rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 4rem !important;
  }

  .pe-xxl-7 {
    padding-right: 6rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 4rem !important;
  }

  .ps-xxl-7 {
    padding-left: 6rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 4rem !important;
  }

  .gap-xxl-7 {
    gap: 6rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 6rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 6rem !important;
  }

  .fs-xxl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xxl-2 {
    font-size: calc(1.325rem + .9vw) !important;
  }

  .fs-xxl-3 {
    font-size: calc(1.3rem + .6vw) !important;
  }

  .fs-xxl-4 {
    font-size: calc(1.275rem + .3vw) !important;
  }

  .fs-xxl-5 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-6 {
    font-size: 1rem !important;
  }

  .fs-xxl-xs {
    font-size: .75rem !important;
  }

  .fs-xxl-sm {
    font-size: .875rem !important;
  }

  .fs-xxl-lg {
    font-size: 1.125rem !important;
  }

  .fs-xxl-xl {
    font-size: 1.25rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }

  .fs-sm-1 {
    font-size: 2.5rem !important;
  }

  .fs-sm-2 {
    font-size: 2rem !important;
  }

  .fs-sm-3 {
    font-size: 1.75rem !important;
  }

  .fs-sm-4 {
    font-size: 1.5rem !important;
  }

  .fs-md-1 {
    font-size: 2.5rem !important;
  }

  .fs-md-2 {
    font-size: 2rem !important;
  }

  .fs-md-3 {
    font-size: 1.75rem !important;
  }

  .fs-md-4 {
    font-size: 1.5rem !important;
  }

  .fs-lg-1 {
    font-size: 2.5rem !important;
  }

  .fs-lg-2 {
    font-size: 2rem !important;
  }

  .fs-lg-3 {
    font-size: 1.75rem !important;
  }

  .fs-lg-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

:root {
  --sk-link-color: #0d6efd;
  --sk-link-color-rgb: 13, 110, 253;
  --sk-link-hover-color: #0a58ca;
  --sk-link-hover-color-rgb: 10, 88, 202;
}

:focus, :focus-visible {
  outline: 2px dotted var(--sk-orange) !important;
  outline-offset: 4px !important;
  z-index: 5 !important;
}

:focus:not(:focus-visible) {
  outline: none !important;
}

.bi {
  width: 1em;
  height: 1em;
  vertical-align: -.125em;
  fill: currentColor;
}

.navbar {
  --sk-navbar-color: #ffffffbf;
  --sk-navbar-hover-color: var(--sk-orange);
  --sk-navbar-active-color: var(--sk-orange);
  --sk-navbar-brand-padding-y: .25rem;
  --sk-navbar-brand-margin-end: 1rem;
  --sk-navbar-nav-link-padding-x: .625rem;
  --sk-navbar-toggler-font-size: 1.5rem;
  --sk-navbar-toggler-border-color: transparent;
  --sk-navbar-toggler-focus-width: 0;
  --sk-navbar-toggler-transition: none;
  background-color: var(--sk-dark);
  min-height: 4.25rem;
}

@media (min-width: 1200px) {
  .navbar {
    --sk-navbar-brand-margin-end: 2rem;
    --sk-navbar-nav-link-padding-x: .875rem;
  }
}

.navbar-brand {
  --size: 44px;
  flex-shrink: 0;
  overflow: hidden;
}

body.home .navbar-brand {
  max-width: var(--size);
}

.navbar-brand > img {
  height: var(--size);
}

.navbar .offcanvas {
  --sk-offcanvas-width: 320px;
  --sk-offcanvas-padding-x: 1.5rem;
  --sk-offcanvas-color: var(--sk-navbar-color);
  --sk-offcanvas-bg: var(--sk-dark);
  --sk-offcanvas-border-width: 0;
}

.navbar .dropdown-menu {
  --sk-dropdown-min-width: 8rem;
  --sk-dropdown-padding-x: .25rem;
  --sk-dropdown-padding-y: .25rem;
  --sk-dropdown-font-size: .875rem;
  --sk-dropdown-link-hover-bg: var(--sk-gray-200);
  --sk-dropdown-link-active-color: #fff;
  --sk-dropdown-link-active-bg: #3b4f6d;
  --sk-dropdown-item-border-radius: .25rem;
}

@media (max-width: 991.98px) {
  .navbar .dropdown-menu {
    --sk-dropdown-min-width: 100%;
  }
}

.navbar .dropdown-menu li + li {
  margin-top: .125rem;
}

.navbar .dropdown-menu .active {
  font-weight: 500;
}

.navbar .dropdown-menu img {
  vertical-align: -.125rem;
}

#post {
  --sk-link-color: red !important;
}

#post .attr-list {
  --attr-li-flex: 0 1 auto;
  --attr-text-color: transparent;
}

#post .attr-list a {
  font: 0 / 0 a;
  display: block;
}

ul.attr-list {
  --attr-size: 2.5rem;
  --attr-icon-color: var(--sk-gray-500);
  --attr-icon-hover-color: var(--sk-orange);
  --attr-text-color: currentColor;
  --attr-text-hover-color: var(--attr-text-color);
  --attr-li-flex: 1 1 6.25em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

ul.attr-list li {
  flex: var(--attr-li-flex);
}

ul.attr-list a {
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  transition: all .25s;
  display: flex;
  color: var(--attr-text-color) !important;
}

ul.attr-list svg {
  fill: var(--attr-icon-color);
  width: var(--attr-size);
  height: var(--attr-size);
  transition: inherit;
}

ul.attr-list a:hover {
  --attr-text-color: var(--attr-text-hover-color);
}

ul.attr-list a:hover svg {
  --attr-icon-color: var(--attr-icon-hover-color);
}

.banner-inner {
  max-width: 1600px;
  margin: 0 auto;
}

.banner img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1600 / 550;
  min-height: 200px;
}

.banner:after {
  content: attr(data-author);
  color: var(--sk-gray-600);
  text-align: right;
  max-width: 1296px;
  margin: 0 auto;
  font-size: .875rem;
  font-style: italic;
  display: block;
}

.sigplus-gallery > ul {
  grid-template-columns: repeat(2, 1fr);
  gap: .5rem;
  display: grid;
}

@media (min-width: 576px) {
  .sigplus-gallery > ul {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .sigplus-gallery > ul {
    grid-template-columns: repeat(4, 1fr);
  }
}

.sigplus-gallery > ul > li {
  margin: 0;
}

a.sigplus-image {
  width: 100%;
  height: 100%;
}

a.sigplus-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#gtx-trans {
  display: none !important;
}

.widget {
  --sk-heading-color: var(--sk-body-color);
  color: var(--sk-gray-700);
  font-size: .875rem;
}

.widget + .widget {
  border-top: 1px solid var(--sk-border-color);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.widget p {
  margin-bottom: .625rem;
}

.card {
  --sk-card-border-radius: 1rem;
  --sk-card-inner-border-radius: 1rem;
  --sk-card-color: var(--sk-gray-700);
}

.card .card-img-top {
  height: 200px;
  object-fit: cover;
  padding: 1px;
}

.card .card-title a {
  color: currentColor;
  display: block;
}

.card .card-title a:hover {
  color: var(--sk-link-color);
}

.card .list-group {
  --sk-list-group-item-padding-x: 0;
}

.accordion {
  --sk-accordion-border-width: 0;
  --sk-accordion-inner-border-radius: 0;
  --sk-accordion-btn-padding-x: 0;
  --sk-accordion-btn-padding-y: .5rem;
  --sk-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=\"http://www.w3.org/2000/svg\" fill=\"%236c757d\" viewBox=\"0 0 16 16\"%3e%3cpath fill-rule=\"evenodd\" d=\"M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\"/%3e%3c/svg%3e");
  --sk-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=\"http://www.w3.org/2000/svg\" fill=\"%236c757d\" viewBox=\"0 0 16 16\"%3e%3cpath fill-rule=\"evenodd\" d=\"M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\"/%3e%3c/svg%3e");
  --sk-accordion-btn-focus-box-shadow: 0;
  --sk-accordion-body-padding-x: .25rem;
  --sk-accordion-body-padding-y: .5rem;
  --sk-accordion-active-color: ;
  --sk-accordion-active-bg: transparent;
}

.nav-aside {
  --sk-nav-link-padding-x: 0;
  --sk-nav-link-padding-y: .25rem;
  --sk-nav-link-color: currentColor;
  --sk-nav-link-hover-color: var(--sk-link-color);
  flex-direction: column;
}

.widget.towns .nav-aside {
  --sk-nav-link-padding-x: .5rem;
}

.nav-aside .nav-link {
  align-items: center;
  display: inline-flex;
}

.nav-aside .nav-link.active {
  pointer-events: none;
  background-color: var(--sk-primary-bg-subtle);
  border-radius: .25rem;
}

.nav-aside .nav-link img {
  width: 50px;
  border-radius: var(--sk-border-radius-xl);
  margin-right: .5rem;
}

.nav-foot {
  --sk-nav-link-padding-x: 0;
  --sk-nav-link-padding-y: 0;
  gap: 1rem 1.75rem;
}

.nav-foot .nav-link img {
  width: 2rem;
  height: auto;
  border: 2px solid #ffffff80;
  border-radius: .375rem;
  margin-right: .375rem;
}

.pagination {
  --sk-pagination-font-size: .875rem;
  --sk-pagination-border-width: 0;
  --sk-pagination-border-radius: .25rem;
  --sk-pagination-hover-color: var(--sk-link-color);
  --sk-pagination-hover-bg: var(--sk-gray-100);
  --sk-pagination-active-color: var(--sk-dark);
  --sk-pagination-active-bg: var(--sk-primary-bg-subtle);
  --sk-pagination-disabled-color: var(--sk-gray-500);
  --sk-pagination-disabled-bg: transparent;
  gap: .25rem;
  font-weight: 500;
}

.pagination > li:last-of-type, .pagination > li:first-of-type {
  --sk-pagination-padding-x: .5rem;
}

.pagination .page-link {
  border-radius: var(--sk-pagination-border-radius);
}

.popover {
  --sk-popover-max-width: 576px;
  --sk-popover-font-size: 1rem;
  --sk-popover-bg: #fff;
  --sk-popover-header-padding-y: 1.25rem;
  --sk-popover-header-font-size: 1.25rem;
  --sk-popover-header-bg: white;
  --sk-popover-arrow-height: .75rem;
  box-shadow: var(--sk-popover-box-shadow);
}

.popover-header {
  border-bottom: 0;
  padding-bottom: 0;
}

#intro {
  background-color: var(--sk-dark);
  position: relative;
}

#intro:after {
  content: "";
  height: 1px;
  background-image: radial-gradient(#ffffff40, #0000 50%);
  display: block;
  position: absolute;
  inset: 100% 0 auto;
}

.intro-brand {
  width: 28rem;
}

.intro-logos {
  max-width: 600px;
  width: 100%;
}

@media (max-width: 575.98px) {
  .intro-logos {
    max-width: 450px;
    font-size: .75rem;
  }
}

.logo-wm {
  height: 4.25em;
}

.logo-kp {
  height: 5em;
}

.logo-pm {
  height: 3.25em;
}

.particles-js-canvas-el {
  width: 100%;
  height: 100%;
  opacity: .75;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

#trail {
  background-color: var(--sk-dark);
  overflow: hidden;
}

.trail-title {
  position: relative;
}

.trail-title span {
  color: var(--sk-orange);
  margin-top: .375rem;
  font-size: 1.125rem;
  display: block;
}

.trail-view {
  transform-origin: center;
}

.trail-view[data-view="two"] {
  background-image: url("trail-bg.da6465bc.png");
  background-position: 85% 100%;
  background-repeat: no-repeat;
  background-size: 600px;
}

@media (min-width: 768px) {
  .trail-view[data-view="two"] {
    background-size: 650px;
  }
}

@media (min-width: 992px) {
  .trail-view[data-view="two"] {
    background-size: 750px;
  }
}

.trail-view.zoomOutView {
  animation: .25s ease-out both zoomOutView;
}

.trail-view.zoomInView {
  animation: .25s ease-in both zoomInView;
}

.trail-view.zoomOutView2 {
  animation: .25s ease-in reverse both zoomInView;
}

.trail-view.zoomInView1 {
  animation: .25s ease-in reverse both zoomOutView;
}

@keyframes zoomOutView {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: scale(.95);
  }
}

@keyframes zoomInView {
  from {
    opacity: 0;
    transform: scale(1.05);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.trail-row {
  flex-direction: column-reverse;
  display: flex;
  position: relative;
}

@media (min-width: 992px) {
  .trail-row {
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
}

.trail-col-post {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 992px) {
  .trail-col-post {
    flex: 50%;
  }
}

[data-view="one"] .trail-col-post {
  display: none;
}

.trail-col-map {
  width: 100%;
  flex: 100%;
}

[data-view="two"] .trail-col-map {
  flex-basis: 50%;
}

@media (max-width: 991.98px) {
  [data-view="two"] .trail-col-map {
    max-width: 450px;
    margin: 0 auto;
  }
}

.trail-post {
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
  position: absolute;
}

.trail-post.current {
  visibility: visible;
  pointer-events: auto;
  z-index: 0;
  position: relative;
}

.trail-post.fadeInLeft, .trail-post.fadeInRight, .trail-post.fadeOutLeft, .trail-post.fadeOutRight {
  visibility: visible;
  animation-fill-mode: both;
}

.trail-post.fadeOutLeft, .trail-post.fadeOutRight {
  z-index: 0;
  animation-duration: .5s;
  animation-timing-function: ease-in;
}

.trail-post.fadeInLeft, .trail-post.fadeInRight {
  z-index: 1;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-delay: .25s;
}

.trail-post.fadeInLeft {
  animation-name: fadeInLeft;
}

.trail-post.fadeInRight {
  animation-name: fadeInRight;
}

.trail-post.fadeOutLeft {
  animation-name: fadeOutLeft;
}

.trail-post.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }
}

.trail-post-title {
  color: #fff;
  text-transform: capitalize;
}

#trail .attr-list {
  --attr-li-flex: 0 1 6em;
  --attr-text-color: white;
}

.trail-post-image {
  width: 100%;
  max-width: 200px;
  filter: hue-rotate(348deg);
  margin-bottom: 25px;
}

.trail-post-lead {
  color: #ffffffb3;
  max-width: 550px;
  font-size: 1.125rem;
  font-weight: lighter;
}

.trail-post-lead:before {
  height: 2px;
  width: 130px;
  content: "";
  background-color: var(--sk-blue);
  margin: 0 auto 1.25rem;
  display: block;
}

.trail-post-lead > a {
  color: var(--sk-orange);
  white-space: nowrap;
  text-decoration: none;
}

[data-view="one"] .map-svg {
  max-height: 700px;
}

[data-view="two"] .map-svg {
  max-height: 564px;
  aspect-ratio: 1;
  margin-inline: auto;
  display: block;
}

[data-view="two"] .trail-map-slide {
  transform-origin: center;
}

[data-view="two"] .trail-map-slide.slide-w-wm {
  transform: scale(2.5)translate(-4.2%, -45%);
}

[data-view="two"] .trail-map-slide.slide-w-kp {
  transform: scale(2.5)translate(12%, -62%);
}

[data-view="two"] .trail-map-slide.slide-w-pm {
  transform: scale(2.5)translate(9.5%, -31%);
}

[data-view="two"] .trail-map-slide.slide-smooth {
  transition: transform .75s cubic-bezier(0, 0, .75, 1);
}

[data-view="two"] .trail-map-slide.slide-w-wm #w-wm .map-svg-track {
  stroke-dasharray: 653.994;
}

[data-view="two"] .trail-map-slide.slide-w-wm.slide-in-right #w-wm .map-svg-track {
  stroke-dashoffset: 653.994px;
}

[data-view="two"] .trail-map-slide.slide-w-wm.slide-in-left #w-wm .map-svg-track {
  stroke-dashoffset: -653.994px;
}

[data-view="two"] .trail-map-slide.slide-w-kp #w-kp .map-svg-track {
  stroke-dasharray: 462.208;
}

[data-view="two"] .trail-map-slide.slide-w-kp.slide-in-right #w-kp .map-svg-track {
  stroke-dashoffset: -462.208px;
}

[data-view="two"] .trail-map-slide.slide-w-kp.slide-in-left #w-kp .map-svg-track {
  stroke-dashoffset: 462.208px;
}

[data-view="two"] .trail-map-slide.slide-w-pm #w-pm .map-svg-track {
  stroke-dasharray: 215.693;
}

[data-view="two"] .trail-map-slide.slide-w-pm.slide-in-right #w-pm .map-svg-track {
  stroke-dashoffset: -215.693px;
}

[data-view="two"] .trail-map-slide.slide-w-pm.slide-in-left #w-pm .map-svg-track {
  stroke-dashoffset: 215.693px;
}

[data-view="two"] .trail-map-slide.slide-w-wm.slide-in-left #w-wm .map-svg-track, [data-view="two"] .trail-map-slide.slide-w-wm.slide-in-right #w-wm .map-svg-track, [data-view="two"] .trail-map-slide.slide-w-kp.slide-in-left #w-kp .map-svg-track, [data-view="two"] .trail-map-slide.slide-w-kp.slide-in-right #w-kp .map-svg-track, [data-view="two"] .trail-map-slide.slide-w-pm.slide-in-left #w-pm .map-svg-track, [data-view="two"] .trail-map-slide.slide-w-pm.slide-in-right #w-pm .map-svg-track {
  animation: .75s both drawTrack;
}

[data-view="two"] .trail-map-slide.slide-w-wm.slide-in-left #w-wm .map-svg-point, [data-view="two"] .trail-map-slide.slide-w-wm.slide-in-right #w-wm .map-svg-point, [data-view="two"] .trail-map-slide.slide-w-kp.slide-in-left #w-kp .map-svg-point, [data-view="two"] .trail-map-slide.slide-w-kp.slide-in-right #w-kp .map-svg-point, [data-view="two"] .trail-map-slide.slide-w-pm.slide-in-left #w-pm .map-svg-point, [data-view="two"] .trail-map-slide.slide-w-pm.slide-in-right #w-pm .map-svg-point, [data-view="two"] .trail-map-slide.slide-w-wm.slide-in-left #w-wm .map-svg-point-name, [data-view="two"] .trail-map-slide.slide-w-wm.slide-in-right #w-wm .map-svg-point-name, [data-view="two"] .trail-map-slide.slide-w-kp.slide-in-left #w-kp .map-svg-point-name, [data-view="two"] .trail-map-slide.slide-w-kp.slide-in-right #w-kp .map-svg-point-name, [data-view="two"] .trail-map-slide.slide-w-pm.slide-in-left #w-pm .map-svg-point-name, [data-view="two"] .trail-map-slide.slide-w-pm.slide-in-right #w-pm .map-svg-point-name {
  animation: .5s .5s both fadeIn;
}

[data-view="two"] .trail-map-slide.slide-w-wm > :not(#w-wm) > :not(.map-svg-region), [data-view="two"] .trail-map-slide.slide-w-kp > :not(#w-kp) > :not(.map-svg-region), [data-view="two"] .trail-map-slide.slide-w-pm > :not(#w-pm) > :not(.map-svg-region) {
  visibility: hidden;
  transition: none;
}

.map-svg-region {
  stroke: #66778a;
  stroke-width: 1px;
  cursor: pointer;
}

[data-view="two"] .map-svg-region {
  fill: #0000;
}

[data-view="two"] .slide-w-wm #w-kp .map-svg-region {
  cursor: url("cursor-d-l.de25a6f4.svg"), auto;
}

[data-view="two"] .slide-w-wm #w-pm .map-svg-region {
  cursor: url("cursor-u-l.95a088fb.svg"), auto;
}

[data-view="two"] .slide-w-kp #w-pm .map-svg-region {
  cursor: url("cursor-u.8ee7f034.svg"), auto;
}

[data-view="two"] .slide-w-kp #w-wm .map-svg-region {
  cursor: url("cursor-u-r.69f8db0a.svg"), auto;
}

[data-view="two"] .slide-w-pm #w-wm .map-svg-region {
  cursor: url("cursor-d-r.f394bd2a.svg"), auto;
}

[data-view="two"] .slide-w-pm #w-kp .map-svg-region {
  cursor: url("cursor-d.81068811.svg"), auto;
}

[data-view="two"] .slide-w-wm #w-wm .map-svg-region, [data-view="two"] .slide-w-kp #w-kp .map-svg-region, [data-view="two"] .slide-w-pm #w-pm .map-svg-region {
  pointer-events: none;
}

.map-svg-region-name {
  fill: #fff;
  pointer-events: none;
  text-transform: uppercase;
  font-size: 1rem;
  transition: fill .2s;
}

.map-svg-group.hover .map-svg-region-name {
  fill: var(--sk-orange);
}

[data-view="two"] .map-svg-region-name {
  display: none;
}

.map-svg-track {
  fill: none;
  stroke: #ffffff80;
  stroke-width: 2px;
  pointer-events: none;
  transition: stroke .2s;
}

.map-svg-group.hover .map-svg-track, [data-view="two"] .map-svg-track {
  stroke: var(--sk-orange);
}

@keyframes drawTrack {
  to {
    stroke-dashoffset: 0;
  }
}

.map-svg-point {
  r: 4px;
  stroke: #fff;
  stroke-width: 1.5px;
  fill: var(--sk-orange);
  cursor: pointer;
  transition: all .25s;
}

.map-svg-point.current {
  r: 5px;
  fill: var(--sk-blue);
  pointer-events: none;
  transition-duration: .5s;
}

.map-svg-point:not(.active) {
  display: none;
}

.map-svg-point-name {
  fill: #ffffffbf;
  cursor: pointer;
  font-size: .625rem;
  transition: fill .25s;
}

.map-svg-point.hover + .map-svg-point-name {
  fill: var(--sk-orange);
}

.map-svg-point.current + .map-svg-point-name {
  fill: #fff;
  pointer-events: none;
}

.map-svg-point:not(.active) + .map-svg-point-name {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.trail-nav {
  justify-content: center;
  align-items: center;
  gap: .875rem;
  margin-top: 3rem;
  display: flex;
}

[data-view="one"] .trail-nav {
  display: none;
}

.trail-nav-prev, .trail-nav-next, .trail-nav-back {
  box-shadow: none;
  aspect-ratio: 1;
  border-radius: 10rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: color .15s;
  display: flex;
}

.trail-nav-prev:hover, .trail-nav-next:hover, .trail-nav-back:hover {
  color: var(--sk-orange);
}

.trail-nav-prev > svg, .trail-nav-next > svg, .trail-nav-back > svg {
  pointer-events: none;
}

.trail-nav-prev, .trail-nav-next {
  color: #fff;
  width: 2.875rem;
  background: none;
  border: 1px solid #fff;
}

.trail-nav-prev > svg, .trail-nav-next > svg {
  font-size: 1.5rem;
}

.trail-nav-back {
  color: var(--sk-blue);
  width: 3.75rem;
  aspect-ratio: 1;
  background: #fff;
  border: none;
}

.trail-nav-back > svg {
  font-size: 1.75rem;
}

#section-attrs article:not(:target) {
  display: none;
}

ul.attrs-list, #passport .attr-list {
  --attr-size: 3.5rem;
}

#coin {
  background-image: radial-gradient(closest-side, white 25%, var(--sk-gray-200));
}

@media (min-width: 768px) {
  #coin {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("coin-bg.8a0a9315.png"), radial-gradient(50% 25%, white 25%, var(--sk-gray-100));
    background-position: 100%;
  }
}

@media (min-width: 992px) {
  #coin {
    background-position: center;
  }
}

.coin-image {
  max-width: 225px;
}

@media (max-width: 767.98px) {
  .coin-image {
    width: calc(9rem + 10vw);
  }
}

#tourist-point p {
  margin-bottom: .625rem;
}

#foot {
  --sk-link-color: var(--sk-orange);
  --sk-link-color-rgb: var(--sk-orange-rgb);
  --sk-link-hover-color: var(--sk-orange);
  --sk-link-hover-color-rgb: var(--sk-orange-rgb);
  background-color: var(--sk-dark);
}

/*# sourceMappingURL=index.796b6979.css.map */
