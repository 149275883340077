/* Trail Nav */

.trail-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: .875rem;
  [data-view=one] & {
    display: none;
  }
}


.trail-nav-prev,
.trail-nav-next,
.trail-nav-back {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  box-shadow: none;
  aspect-ratio: 1;
  padding: 0;
  transition: color 150ms;
  &:hover {
    color: var(--sk-orange);
  }
  > svg {
    pointer-events: none;
  }
}


.trail-nav-prev,
.trail-nav-next {
  color: white;
  background: transparent;
  border: 1px solid white;
  width: 2.875rem;
  > svg {
    font-size: 1.5rem;
  }
}


.trail-nav-back {
  color: var(--sk-blue);
  background: white;
  border: none;
  width: 3.75rem;
  aspect-ratio: 1;
  > svg {
    font-size: 1.75rem;
  }
}