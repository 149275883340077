/* Trail Post */

.trail-post {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	z-index: -1;

	&.current {
		visibility: visible;
		pointer-events: auto;
		position: relative;
		z-index: 0;
	}

	&.fadeInLeft,
	&.fadeInRight,
	&.fadeOutLeft,
	&.fadeOutRight {	
		animation-fill-mode: both;
		visibility: visible;
	}
	&.fadeOutLeft,
	&.fadeOutRight {
		animation-duration: 500ms;
		animation-timing-function: ease-in;
		z-index: 0;
	}
	&.fadeInLeft,
	&.fadeInRight {
		animation-duration: 500ms;
		animation-delay: 250ms;
		animation-timing-function: ease-out;
		z-index: 1;
	}
	&.fadeInLeft { 
		animation-name: fadeInLeft;
	}
	&.fadeInRight { 
		animation-name: fadeInRight;
	}
	&.fadeOutLeft { 
		animation-name: fadeOutLeft;
	}
	&.fadeOutRight { 
		animation-name: fadeOutRight;
	}
	//
	@keyframes fadeInLeft {
		from {
			opacity: 0;
			transform: translate3d(-100px, 0, 0);
		}
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	@keyframes fadeInRight {
		from {
			opacity: 0;
			transform: translate3d(100px, 0, 0);
		}
		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	@keyframes fadeOutLeft {
		from { opacity: 1; }
		to { 
			opacity: 0; 
			transform: translate3d(-100px, 0, 0); 
		}
	}

	@keyframes fadeOutRight {
		from { opacity: 1; }
		to { 
			opacity: 0; 
			transform: translate3d(100px, 0, 0); 
		}
	}
}


.trail-post-title {
	color: white;
	text-transform: capitalize;
}


#trail .attr-list {
	--attr-li-flex: 0 1 6em;
	--attr-text-color: white;
}


.trail-post-image {
	width: 100%;
	max-width: 200px;
	margin-bottom: 25px;
	filter: hue-rotate(348deg);
}


.trail-post-lead {
	font-size: $font-size-lg;
	font-weight: $font-weight-lighter;
	color: rgba(255 255 255 / 70%);
	max-width: 550px;

	// blue line
	&:before {
		height: 2px;
    width: 130px;
    content: "";
		background-color: var(--sk-blue);
    margin: 0 auto 1.25rem;
    display: block;
	}
	// readmore link
	> a {
		color: var(--sk-orange);
		white-space: nowrap;
		text-decoration: none;
	}
}