.popover {
  // --sk-popover-zindex: 1070;
  --sk-popover-max-width: 576px;
  --sk-popover-font-size: 1rem;
  --sk-popover-bg: #ffffff;
  // --sk-popover-border-width: var(--sk-border-width);
  // --sk-popover-border-color: var(--sk-primary);
  // --sk-popover-border-radius: var(--sk-border-radius-lg);
  // --sk-popover-inner-border-radius: calc(var(--sk-border-radius-lg) - var(--sk-border-width));
  // --sk-popover-box-shadow: 0 .5rem 1rem rgba(var(--sk-body-color-rgb), .15);
  // --sk-popover-header-padding-x: 1rem;
  --sk-popover-header-padding-y: 1.25rem;
  --sk-popover-header-font-size: #{$font-size-xl};
  // --sk-popover-header-color: white;
  --sk-popover-header-bg: white;
  // --sk-popover-body-padding-x: 1rem;
  // --sk-popover-body-padding-y: 1rem;
  // --sk-popover-body-color: var(--sk-body-color);
  // --sk-popover-arrow-width: 1rem;
  --sk-popover-arrow-height: .75rem;
  // --sk-popover-arrow-border: var(--sk-popover-border-color);

  box-shadow: var(--sk-popover-box-shadow);
}


.popover-header {
  border-bottom: 0;
  padding-bottom: 0;
}