/* banner */

// .banner {}

.banner-inner {
  max-width: 1600px;
  margin: 0 auto;
}

.banner img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1600 / 550;
  min-height: 200px;
}

.banner::after {
  content: attr(data-author);
  color: var(--sk-gray-600);
  text-align: right;
  max-width: 1296px;
  margin: 0 auto;
  font-size: .875rem;
  font-style: italic;
  display: block;
}
