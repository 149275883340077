/* Gallery */

.sigplus-gallery {

  > ul {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2,1fr);
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3,1fr);
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4,1fr);
    }
  }

  > ul > li {
    margin: 0;
  }
}

a.sigplus-image {
 width: 100%;
 height: 100%;

 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
 }
}

#gtx-trans {
  display: none !important;
}