/* Section Attribute */

#section-attrs {
	article:not(:target) {
		display: none;
	}
}


ul.attrs-list {
	--attr-size: 3.5rem;
}

.attrs-content {

	
}