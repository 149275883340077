/* Section Copernicoin */

#coin {
	background-image: radial-gradient(closest-side, white 25%, var(--sk-gray-200));
  @include media-breakpoint-up(md) {
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-image: url(../img/coin-bg.png), radial-gradient(50% 25%, white 25%, var(--sk-gray-100));
		background-position: center right;
	}
	@include media-breakpoint-up(lg) {
		background-position: center;
	}
}

.coin-image {
	max-width: 225px;
  @include media-breakpoint-down(md) {
    width: calc(9rem + 10vw);
  }
}