/* Accordion */
$chevron-down: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="#6c757d" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');

.accordion {
  // --sk-accordion-color: var(--sk-body-color);
  // --sk-accordion-bg: var(--sk-body-bg);
  // --sk-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  // --sk-accordion-border-color: var(--sk-border-color);
  --sk-accordion-border-width: 0;
  // --sk-accordion-border-radius: 0;
  --sk-accordion-inner-border-radius: 0;
  --sk-accordion-btn-padding-x: 0;
  --sk-accordion-btn-padding-y: .5rem;
  // --sk-accordion-btn-color: var(--sk-body-color);
  // --sk-accordion-btn-bg: var(--sk-accordion-bg);
  --sk-accordion-btn-icon: #{escape-svg($chevron-down)};
  // --sk-accordion-btn-icon-width: 1.25rem;
  // --sk-accordion-btn-icon-transform: rotate(-180deg);
  // --sk-accordion-btn-icon-transition: transform .2s ease-in-out;
  --sk-accordion-btn-active-icon: #{escape-svg($chevron-down)};
  // --sk-accordion-btn-focus-border-color: #8b9cb0;
  --sk-accordion-btn-focus-box-shadow: 0;
  --sk-accordion-body-padding-x: .25rem;
  --sk-accordion-body-padding-y: .5rem;
  --sk-accordion-active-color: ;
  --sk-accordion-active-bg: transparent;
}