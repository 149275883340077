#foot {
	--sk-link-color: var(--sk-orange);// #{$orange-400};
  --sk-link-color-rgb: var(--sk-orange-rgb);//#{to-rgb($orange-400)};
  --sk-link-hover-color: var(--sk-orange);// #{$orange};
  --sk-link-hover-color-rgb: var(--sk-orange-rgb); //#{to-rgb($orange)};

	background-color: var(--sk-dark);

	/* .nav {
		--sk-nav-link-color: rgba(255 255 255 / 75%);
		--sk-nav-link-hover-color: var(--sk-orange);
		--sk-nav-link-active-color: var(--sk-orange);
		--sk-nav-link-disabled-color: rgba(255 255 255 / 40%);
	} */
}