/* Attribut List */

ul.attr-list {
  --attr-size: 2.5rem;
  --attr-icon-color: var(--sk-gray-500);
  --attr-icon-hover-color: var(--sk-orange);
  --attr-text-color: currentColor;
  --attr-text-hover-color: var(--attr-text-color);
  --attr-li-flex: 1 1 6.25em; // 100px
  //
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0;
  list-style: none;
  display: flex;

  li {
    flex: var(--attr-li-flex)
  }

  a {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    transition: all .25s;
    display: flex;
    color: var(--attr-text-color) !important;
  }

  svg {
    fill: var(--attr-icon-color);
    width: var(--attr-size);
    height: var(--attr-size);
    transition: inherit;
  }

  a:hover {
    --attr-text-color: var(--attr-text-hover-color);
    svg {
      --attr-icon-color: var(--attr-icon-hover-color);
    }
  }

}