/* Site Header */

.navbar {
  // --sk-navbar-padding-x: 0;
  // --sk-navbar-padding-y: 0.5rem;
  --sk-navbar-color: rgba(255 255 255 / 75%);
  --sk-navbar-hover-color: var(--sk-orange);
  // --sk-navbar-disabled-color: rgba(var(--sk-emphasis-color-rgb), 0.3);
  --sk-navbar-active-color: var(--sk-orange);
  --sk-navbar-brand-padding-y: 0.25rem;
  --sk-navbar-brand-margin-end: 1rem;
  // --sk-navbar-brand-font-size: 1.25rem;
  // --sk-navbar-brand-color: rgba(var(--sk-emphasis-color-rgb), 1);
  // --sk-navbar-brand-hover-color: rgba(var(--sk-emphasis-color-rgb), 1);
  --sk-navbar-nav-link-padding-x: 0.625rem;
  // --sk-navbar-toggler-padding-y: 0.25rem;
  // --sk-navbar-toggler-padding-x: 0.75rem;
  --sk-navbar-toggler-font-size: 1.5rem;
  // --sk-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
  --sk-navbar-toggler-border-color: transparent;
  // --sk-navbar-toggler-border-radius: 0.375rem;
  --sk-navbar-toggler-focus-width: 0;
  --sk-navbar-toggler-transition: none;

  background-color: var(--sk-dark);
  min-height: 4.25rem;

  @include media-breakpoint-up(xl) {
    --sk-navbar-brand-margin-end: 2rem;
    --sk-navbar-nav-link-padding-x: .875rem;
  }
}


// .navbar-nav {
//   --sk-nav-link-font-weight: $font-weight-medium;
// }


.navbar-brand {
  --size: 44px;
  flex-shrink: 0;
  overflow: hidden;
  body.home & {
    max-width: var(--size);
  }
  > img {
    height: var(--size);
  }
}

.navbar .offcanvas {
  // --sk-offcanvas-zindex: 1045;
  --sk-offcanvas-width: 320px;
  // --sk-offcanvas-height: 30vh;
  --sk-offcanvas-padding-x: 1.5rem;
  // --sk-offcanvas-padding-y: 1rem;
  --sk-offcanvas-color: var(--sk-navbar-color);
  --sk-offcanvas-bg: var(--sk-dark);
  --sk-offcanvas-border-width: 0;
  // --sk-offcanvas-border-color: var(--sk-border-color-translucent);
  // --sk-offcanvas-box-shadow: 0 .125rem .25rem rgba(var(--sk-body-color-rgb), .075);
  // --sk-offcanvas-transition: transform .3s ease-in-out;
  // --sk-offcanvas-title-line-height: 1.5;
}

/* .navbar .btn-link {
  --sk-btn-color: var(--sk-navbar-color);
  --sk-btn-hover-color: var(--sk-navbar-hover-color);
  --sk-btn-active-color: var(--sk-navbar-active-color);
} */

.navbar .dropdown-menu {
  // --sk-dropdown-zindex: 1000;
  --sk-dropdown-min-width: 8rem;
  @include media-breakpoint-down(lg) {
    --sk-dropdown-min-width: 100%;
  }
  --sk-dropdown-padding-x: .25rem;
  --sk-dropdown-padding-y: .25rem;
  // --sk-dropdown-spacer: .125rem;
  --sk-dropdown-font-size: #{$font-size-sm};
  // --sk-dropdown-color: var(--sk-body-color);
  // --sk-dropdown-bg: var(--sk-body-bg);
  // --sk-dropdown-border-color: var(--sk-border-color-translucent);
  // --sk-dropdown-border-radius: .375rem;
  // --sk-dropdown-border-width: var(--sk-border-width);
  // --sk-dropdown-inner-border-radius: calc(.375rem - var(--sk-border-width));
  // --sk-dropdown-divider-bg: var(--sk-border-color-translucent);
  // --sk-dropdown-divider-margin-y: .5rem;
  // --sk-dropdown-box-shadow: 0 .5rem 1rem rgba(var(--sk-body-color-rgb), .15);
  // --sk-dropdown-link-color: var(--sk-body-color);
  // --sk-dropdown-link-hover-color: var(--sk-body-color);
  --sk-dropdown-link-hover-bg: var(--sk-gray-200);
  --sk-dropdown-link-active-color: #fff;
  --sk-dropdown-link-active-bg: #3b4f6d;
  // --sk-dropdown-link-disabled-color: #adb5bd;
  // --sk-dropdown-item-padding-x: 1rem;
  // --sk-dropdown-item-padding-y: .25rem;
  --sk-dropdown-item-border-radius: .25rem;
  // --sk-dropdown-header-color: #6c757d;
  // --sk-dropdown-header-padding-x: 1rem;
  // --sk-dropdown-header-padding-y: .5rem;

  li + li {
    margin-top: .125rem;
  }

  .active {
    //color: white;
    font-weight: 500;
    // background-color: $primary;
  }

  img {
    vertical-align: -0.125rem;
  }
}