/* nav */

.nav-aside {
  --sk-nav-link-padding-x: 0;
  --sk-nav-link-padding-y: .25rem;
  --sk-nav-link-color: currentColor;
  --sk-nav-link-hover-color: var(--sk-link-color);
  flex-direction: column;

  .widget.towns & { // only for towns
    --sk-nav-link-padding-x: .5rem;
  }

  .nav-link {
    // display: inline-block;
    display: inline-flex;
    align-items: center;

    &.active {
      pointer-events: none;
      background-color: var(--sk-primary-bg-subtle);
      border-radius: 0.25rem;
    }

    img {
      width: 50px;
      border-radius: var(--sk-border-radius-xl);
      margin-right: .5rem;
    }
  }
}


.nav-foot {
  --sk-nav-link-padding-x: 0;
  --sk-nav-link-padding-y: 0;
  row-gap: 1rem;
  column-gap: 1.75rem;

  .nav-link {
    img {
      width: 2rem;
      height: auto;
      border: 2px solid rgba(255 255 255 / 50%);
      border-radius: .375rem;
      margin-right: .375rem;
    }
  }
}