/* Section Passport */

#passport {
  // background-image: url(../img/sk-section-attrs-bg.png);
	// background-repeat: no-repeat;
	// background-position: bottom -130px left -110px;
	// background-position: 70% -35%;
	// background-size: 600px;
}


#passport .attr-list {
	--attr-size: 3.5rem;
}
