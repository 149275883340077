/* Section Intro */

#intro {
  //background-image: linear-gradient(180deg, var(--sk-dark), transparent);
  background-color: var(--sk-dark);
  position: relative;

  // bottom gradient line
  &:after { 
    content: "";
    height: 1px;
    background-image: radial-gradient(rgb(255 255 255 / 25%), transparent 50%);
    display: block;
    position: absolute;
    inset: 100% 0 auto;    
	}
}

.intro-brand {
  width: 28rem;
}
.intro-logos {
  max-width: 600px;
  width: 100%;
  @include media-breakpoint-down(sm) {
    font-size: $font-size-xs;
    max-width: 450px;
  }
}
.logo-wm { height: 4.25em; }
.logo-kp { height: 5em; }
.logo-pm { height: 3.25em; }

/* for particles */

.particles-js-canvas-el {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .75;
  z-index: 0;
}